import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";
import { mapState } from "vuex";
export default {
    data: () => ({
        user: null,
        site: null,
        heyGovFiles: process.env.VUE_APP_HEYGOV_FILES,
        heyGovApi: process.env.VUE_APP_HEYGOV_API,
        cms6Url: process.env.VUE_APP_JUNIPER_API,
        heyGovMeetRepoApi: null,
        headers: null,
        controller: null,
        filesToUpload: [],
        uploadFilesErrorResponse: {
            agendaErrorResponse: {},
            agendaPacketErrorResponse: {},
            meetingMinutesErrorResponse: {},
            additionalFileErrorResponse: {},
        },
    }),
    computed: {
        ...mapState(["subscribedToMinutes"]),
    },
    methods: {
        prepareForFetch() {
            this.user = this.getCurrentUser();
            if (!this.user || !this.user.auth) {
                return;
            }
            if (this.controller) {
                this.controller.abort();
            }
            this.controller = new AbortController();

            this.site = this.getSite();

            if (process.env.NODE_ENV == "production" && this.site.url != "https://cityofnis.com") {
                this.heyGovMeetRepoApi = this.heyGovApi + this.user.data.heygov_jurisdiction + "/meetings/";
            } else {
                this.heyGovMeetRepoApi = this.heyGovApi + "heyville.org/meetings/";
            }

            this.headers = { Authorization: "Bearer " + this.user.auth };
            this.heyGovHeaders = { Authorization: "Bearer " + this.user.data.heygov_api_key };
        },
        /*==========================================
		=            MEETING REPOSITORY            =
		==========================================*/
        async getMeetingRepositories(useParams = false, page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {};
            if (useParams) {
                params = {
                    _embed: 1,
                    page: page,
                    limit: perPage,
                    order: order, // 'asc'
                    order_by: orderBy == "date" ? "starts_at" : orderBy,
                    q: querySearch,
                    status: "publish",
                    api: 1,
                };
            }

            try {
                let response = await this.axios.get(this.heyGovMeetRepoApi, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    signal: this.controller.signal,
                    // headers: this.headers,
                    params: params,
                });

                // const updatedData = await Promise.all(
                //     response.data.map(
                //         async (meetingRepository) =>
                //             await {
                //                 ...meetingRepository,
                //                 ...{
                //                     meta: { ["tw-notifications-sent"]: meetingRepository.metadata && meetingRepository.metadata["tw-notifications-sent"] ? meetingRepository.metadata["tw-notifications-sent"] : "" },
                //                     title: { ...{ rendered: meetingRepository.title } },
                //                     meeting_date: meetingRepository.starts_at,
                //                     twd_repository_cat: meetingRepository.categories,
                //                     agenda: meetingRepository.agenda_file_path ? { guid: this.heyGovFiles + meetingRepository.agenda_file_path } : null,
                //                     agenda_pack: meetingRepository.agenda_pack_file_path ? { guid: this.heyGovFiles + meetingRepository.agenda_pack_file_path } : null,
                //                     meeting_minutes: meetingRepository.meeting_minutes_file_path ? { guid: this.heyGovFiles + meetingRepository.meeting_minutes_file_path } : null,
                //                     times_notified: meetingRepository.metadata["times_notified"] ?? 0,
                //                     id: meetingRepository.id,
                //                 },
                //             }
                //     )
                // );

                const updatedData = await Promise.all(
                    response.data.map(
                        async (meetingRepository) =>
                            await {
                                ...meetingRepository,
                                ...{
                                    meta: { ["tw-notifications-sent"]: meetingRepository.metadata && meetingRepository.metadata["tw-notifications-sent"] ? meetingRepository.metadata["tw-notifications-sent"] : "" },
                                    title: { ...{ rendered: meetingRepository.title } },
                                    meeting_date: meetingRepository.starts_at,
                                    twd_repository_cat: meetingRepository.categories,
                                    agenda: meetingRepository.agenda_file_url ? { guid: meetingRepository.agenda_file_url } : null,
                                    agenda_pack: meetingRepository.agenda_pack_file_url ? { guid: meetingRepository.agenda_pack_file_url } : null,
                                    meeting_minutes: meetingRepository.meeting_minutes_file_url ? { guid: meetingRepository.meeting_minutes_file_url } : null,
                                    times_notified: meetingRepository.metadata["times_notified"] ?? 0,
                                    id: meetingRepository.id,
                                },
                            }
                    )
                );

                response.headers["x-wp-total"] = response.headers["x-total"];

                // additional_doc:""
                // additional_file:""
                // additional_url:""
                // agenda:""
                // agenda_pack:""
                // date:"2022-12-08T15:26:15"
                // date_gmt:"2022-12-08T15:26:15"
                // guid:{rendered: "http://localhost/tw/clayton-theme/twd_repository/test-3/"}
                // id:4866
                // link:"http://localhost/tw/clayton-theme/twd_repository/test-3/"
                // meeting_date:"2022-12-13"
                // meeting_minutes:""
                // meta:{tw-notifications-sent: "1 subscribers were notified"}
                // modified:"2022-12-08T15:26:15"
                // modified_gmt:"2022-12-08T15:26:15"
                // notes:""
                // parent:0
                // slug:"test-3"
                // sound:""
                // status:"publish"
                // template:""
                // times_notified:"2"
                // title:{rendered: "Test"}
                // twd_repository_cat:[14]
                // type:"twd_repository"
                // video:""

                response.data = updatedData;

                return response;
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getMeetingRepositoryCategories(allowed_terms = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
                api: 1,
                // include: [13]
            };

            if (allowed_terms) {
                params = { ...params, ...{ include: allowed_terms } };
            }

            let res = await this.axios.get(this.heyGovMeetRepoApi + `categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                // headers: this.headers,
                params: params,
            });

            if (allowed_terms !== null) {
                res.data = res.data.filter((cat) => allowed_terms.includes(cat.id));
            }

            return res;
        },
        async getMeetingRepository(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                hide_empty: 0,
                api: 1,
                expand: "agenda_items",
            };
            let response = await this.axios.get(this.heyGovMeetRepoApi + id, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                // headers: this.headers,
                params: params,
            });

            // const updatedData = {
            //     ...response.data,
            //     ...{
            //         meta: { ["tw-notifications-sent"]: response.data.metadata["tw-notifications-sent"] ? response.data.metadata["tw-notifications-sent"] : "" },
            //         title: { ...{ rendered: response.data.title } },
            //         meeting_date: response.data.starts_at,
            //         agenda: response.data.agenda_file_path ? { guid: this.heyGovFiles + response.data.agenda_file_path, filename: response.data.agenda_file_path.split("/").pop() } : "",
            //         agenda_pack: response.data.agenda_pack_file_path ? { guid: this.heyGovFiles + response.data.agenda_pack_file_path, filename: response.data.agenda_pack_file_path.split("/").pop() } : "",
            //         meeting_minutes: response.data.meeting_minutes_file_path ? { guid: this.heyGovFiles + response.data.meeting_minutes_file_path, filename: response.data.meeting_minutes_file_path.split("/").pop() } : "",
            //         additional_file: response.data.additional_file_path ? { guid: this.heyGovFiles + response.data.additional_file_path, filename: response.data.additional_file_path.split("/").pop() } : "",
            //         times_notified: response.data.metadata["times_notified"] ?? 0,
            //         id: response.data.id,
            //         twd_repository_cat: response.data.categories,
            //         // Additional URL
            //         additional_url: response.data.metadata["additional_url"] ?? "",
            //         // Additional Public Name
            //         additional_doc: response.data.metadata["additional_doc"] ?? "",
            //         video: response.data.video_public_url ?? "",
            //         sound: response.data.audio_public_url ?? "",
            //     },
            // };

            const updatedData = {
                ...response.data,
                ...{
                    meta: { ["tw-notifications-sent"]: response.data.metadata["tw-notifications-sent"] ? response.data.metadata["tw-notifications-sent"] : "" },
                    title: { ...{ rendered: response.data.title } },
                    meeting_date: response.data.starts_at,
                    agenda: response.data.agenda_file_url ? { guid: response.data.agenda_file_url, filename: response.data.agenda_file_url.split("/").pop() } : "",
                    agenda_pack: response.data.agenda_pack_file_url ? { guid: response.data.agenda_pack_file_url, filename: response.data.agenda_pack_file_url.split("/").pop() } : "",
                    meeting_minutes: response.data.meeting_minutes_file_url ? { guid: response.data.meeting_minutes_file_url, filename: response.data.meeting_minutes_file_url.split("/").pop() } : "",
                    additional_file: response.data.additional_file_url ? { guid: response.data.additional_file_url, filename: response.data.additional_file_url.split("/").pop() } : "",
                    times_notified: response.data.metadata["times_notified"] ?? 0,
                    id: response.data.id,
                    twd_repository_cat: response.data.categories,
                    // Additional URL
                    additional_url: response.data.metadata["additional_url"] ?? "",
                    // Additional Public Name
                    additional_doc: response.data.metadata["additional_doc"] ?? "",
                    video: response.data.video_public_url ?? "",
                    sound: response.data.audio_public_url ?? "",
                },
            };

            response.data = updatedData;

            return response;
        },
        async saveRepository(repository, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) return;

            repository = {
                ...repository,
                ...{
                    title: repository.title,
                    starts_at_local: repository.meeting_date,
                    categories: repository.twd_repository_cat,
                    status: "publish",
                    audio_public_url: repository.sound,
                    video_public_url: repository.video,
                    metadata: {
                        additional_url: repository.additional_url,
                        additional_doc: repository.additional_doc,
                    },
                },
            };

            let response = await this.axios.post(this.heyGovMeetRepoApi, repository, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                // headers: this.heyGovHeaders
                params: {
                    apiKey: this.user.data.heygov_api_key,
                    api: 1,
                },
            });

            await this.uploadHeyGovMeetingFiles(response.data.id);
            return response;
        },
        async updateRepository(repository, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            /*----------  Metadata update  ----------*/
            let updatedMetadata = repository.metadata;
            if (repository.additional_url) {
                updatedMetadata = { ...updatedMetadata, ...{ additional_url: repository.additional_url } };
            } else if (repository.additional_url === "") {
                updatedMetadata = { ...updatedMetadata, ...{ additional_url: "" } };
            }
            if (repository.additional_doc) {
                updatedMetadata = { ...updatedMetadata, ...{ additional_doc: repository.additional_doc } };
            } else if (repository.additional_doc === "") {
                updatedMetadata = { ...updatedMetadata, ...{ additional_doc: "" } };
            }

            let repositoryData = {
                title: repository.title,
                starts_at_local: repository.meeting_date,
                categories: repository.twd_repository_cat,
                // status: 'publish',
                audio_public_url: repository.sound,
                video_public_url: repository.video,
                notes: repository.notes,
                metadata: updatedMetadata,
            };

            /*----------  Remove files  ----------*/
            // agenda
            if (!repository.agenda && repository.agenda_file_url) {
                await this.axios.post(
                    this.heyGovMeetRepoApi + repository.id + "/remove-agenda",
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    }
                );
            }
            // agenda pack
            if (!repository.agenda_pack && repository.agenda_pack_file_url) {
                await this.axios.post(
                    this.heyGovMeetRepoApi + repository.id + "/remove-agenda-pack-file",
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    }
                );
            }
            // minutes
            if (!repository.meeting_minutes && repository.meeting_minutes_file_url) {
                await this.axios.post(
                    this.heyGovMeetRepoApi + repository.id + "/remove-meeting-minutes-file",
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    }
                );
            }
            // additional file
            if (!repository.additional_file && repository.additional_file_url) {
                await this.axios.post(
                    this.heyGovMeetRepoApi + repository.id + "/remove-additional-file",
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    }
                );
            }

            let response = await this.axios.put(this.heyGovMeetRepoApi + repository.id, repositoryData, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                // headers: this.heyGovHeaders
                params: { apiKey: this.user.data.heygov_api_key, api: 1 },
            });

            await this.uploadHeyGovMeetingFiles(repository.id);

            return response;
        },
        async deleteMeetingRepository(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let res = await this.axios.delete(this.heyGovMeetRepoApi + id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                // headers: this.heyGovHeaders
                params: { apiKey: this.user.data.heygov_api_key, api: 1 },
            });

            this.axios.post(
                this.cms6Url + `clients/regenerate/meetings`,
                {},
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );

            return res;
        },
        async meetingRepositoryNotify(id) {
            return await this.axios.post(
                this.cms6Url + `meeting-repository/notify/${id}`,
                { jurisdiction_id: this.user.data.heygov_jurisdiction },
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );
        },

        /*=====  End of MEETING REPOSITORY  ======*/
        /*===================================
        =            REPOSITORY AGENDA ITEMS          =
        ===================================*/
        async setAgendaItemsOrder(repository, newOrder) {
            this.prepareForFetch();

            const order = newOrder.map((item, index) => {
                return {
                    id: item.id,
                    parent_id: item.parent_id,
                    order: index,
                };
            });

            try {
                const response = await this.axios.post(this.heyGovMeetRepoApi + repository.id + "/agenda-items/order", order, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                console.error("Agenda list items order error.", error);
            }
        },

        async getAgendaItems(repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.get(this.heyGovMeetRepoApi + repository.id + `/agenda-items`, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                console.error("Agenda list items get error.", error);
            }
        },

        async createAgendaItem(repository, agendaItem) {
            this.prepareForFetch();

            try {
                const response = await this.axios.post(this.heyGovMeetRepoApi + repository.id + `/agenda-items`, agendaItem, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });

                return response;
            } catch (error) {
                console.error("Agenda list item create error.", error);
            }
        },

        async editAgendaItem(repository, agendaItemId, agendaItemChanges) {
            this.prepareForFetch();

            try {
                const response = await this.axios.put(this.heyGovMeetRepoApi + repository.id + `/agenda-items/${agendaItemId}`, agendaItemChanges, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                console.error("Agenda list item edit error.", error);
            }
        },

        async deleteAgendaItem(repository, agendaItem) {
            this.prepareForFetch();

            try {
                const response = await this.axios.delete(this.heyGovMeetRepoApi + repository.id + `/agenda-items/${agendaItem.id}`, agendaItem, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                console.error("Agenda list item delete error.", error);
            }
        },

        /*===================================
        =         MEETING MINUTES           =
        ===================================*/

        async startClerkMinutes() {
            this.prepareForFetch();

            const REFERRAL_CODE = "trytoday";
            try {
                const response = await this.axios.post(
                    this.heyGovMeetRepoApi + `/start-clerkminutes-subscription`,
                    {
                        source: "Town Web Dashboard",
                        name: this.user?.data.name || "Name is not defined",
                        email: this.user?.data.email || "Email address is not defined",
                        jurisdiction: this.user?.data.heygov_jurisdiction || "No jurisdiction defined",
                        referral_code: REFERRAL_CODE,
                    },
                    {
                        params: {
                            apiKey: this.user.data.heygov_api_key,
                            api: 1,
                        },
                    }
                );
                return response;
            } catch (error) {
                console.error("Error starting ClerkMinutes", error);
            }
        },

        async generateMinutes(repository, body) {
            this.prepareForFetch();

            try {
                const response = await this.axios.post(
                    this.heyGovMeetRepoApi + repository.id + `/generate-minutes-stream`,
                    {
                        ...body,
                    },
                    {
                        params: {
                            apiKey: this.user.data.heygov_api_key,
                            api: 1,
                        },
                    }
                );
                return response;
            } catch (error) {
                console.error("Agenda list item delete error.", error);
            }
        },

        async downloadMinutesAsDocx(repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.get(this.heyGovMeetRepoApi + repository.id + `/minutes/save/docx?person_id=${repository.person_id}`, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                console.error("Download minutes as DOCX error.", error);
            }
        },

        /*=====  End of MEETING MINUTES  ======*/

        /*===================================
        =            TRANSCRIPT            =
        ===================================*/

        async loadMeetingTranscript(repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.get(this.heyGovMeetRepoApi + repository.id + "/transcript", {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                return error;
            }
        },

        async loadJurisdictionSpeakers(repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.get(this.heyGovMeetRepoApi + repository.id + "speakers", {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                return error;
            }
        },

        async updateMeeting(fields, repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.put(this.heyGovMeetRepoApi + repository.id, fields, {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                return error;
            }
        },

        async deleteRecording(repository) {
            this.prepareForFetch();

            try {
                const response = await this.axios.delete(this.heyGovMeetRepoApi + repository.id + "/audio-video-transcript", {
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });
                return response;
            } catch (error) {
                return error;
            }
        },

        /*=====  End of TRANSCRIPT  ======*/

        /*=====  End of REPOSITORY AGENDA ITEMS  ======*/
        /*===================================
        =            FILE UPLOAD            =
        ===================================*/
        uploadFile(file, type = null) {
            return new Promise((resolve) => {
                this.filesToUpload[type] = file;

                let response = { data: { source_url: file.name } };

                resolve(response);
            });
        },

        async uploadAudioVideoFile(repository, file) {
            this.prepareForFetch();

            try {
                const response = await this.axios.post(this.heyGovMeetRepoApi + repository.id + `/audio-video-link`, {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    params: {
                        apiKey: this.user.data.heygov_api_key,
                        api: 1,
                    },
                });

                return response;
            } catch (error) {
                return error;
            }
        },

        async getFileObject(url) {
            try {
                const response = await this.axios.get(url, {
                    responseType: "blob",
                    headers: {
                        Accept: "application/octet-stream",
                    },
                });

                return response.data;
            } catch (error) {
                console.error("Error getting file:", error);
                return;
            }
        },

        async processAgenda(repository) {
            let fileObject;

            if (!this.filesToUpload.agenda) {
                const fileObjectResponse = await this.getFileObject(repository.agenda_file_url);
                if (fileObjectResponse) fileObject = fileObjectResponse;
            } else {
                fileObject = this.filesToUpload.agenda;
            }

            const formDataAgenda = new FormData();
            const fileName = repository?.agenda?.filename ? repository?.agenda?.filename : this.filesToUpload.agenda.name;
            formDataAgenda.append("file", fileObject, fileName);

            if (fileObject) {
                try {
                    const response = await this.axios.post(this.heyGovMeetRepoApi + "process-meeting-agenda", formDataAgenda, {
                        headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
                        params: {
                            apiKey: this.user.data.heygov_api_key,
                            api: 1,
                        },
                    });
                    return response;
                } catch (error) {
                    this.uploadFilesErrorResponse.agendaErrorResponse = {
                        type: "agenda",
                        status: error.response?.status || 500,
                        message: error.response?.data.message || "",
                    };
                    return this.uploadFilesErrorResponse;
                }
            }
        },

        async uploadHeyGovMeetingFiles(meeting_id) {
            if (this.filesToUpload.agenda) {
                const formDataAgenda = new FormData();
                formDataAgenda.append("file", this.filesToUpload.agenda);
                try {
                    await this.axios.post(this.heyGovMeetRepoApi + meeting_id + "/upload-agenda-file", formDataAgenda, {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
                        params: {
                            apiKey: this.user.data.heygov_api_key,
                            api: 1,
                            await_processing: this.subscribedToMinutes ? 1 : 0,
                        },
                    });
                } catch (error) {
                    this.uploadFilesErrorResponse.agendaErrorResponse = {
                        type: "agenda",
                        status: error.response?.status || 500,
                        message: error.response?.data.message || "",
                    };
                    return this.uploadFilesErrorResponse;
                }
                // if (res && res.status === 200) {
                //     // save file data to cms6 db too to have it for media library
                //     this.saveFileDataToCMS6DB(this.filesToUpload.agenda);
                // }
            }

            if (this.filesToUpload["agenda-packet"]) {
                const formDataAgendaPacket = new FormData();
                formDataAgendaPacket.append("file", this.filesToUpload["agenda-packet"]);
                try {
                    await this.axios.post(this.heyGovMeetRepoApi + meeting_id + "/upload-agenda-pack-file", formDataAgendaPacket, {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    });
                } catch (error) {
                    this.uploadFilesErrorResponse.agendaErrorResponse = {
                        type: "agenda packet",
                        status: error.response?.status || 500,
                        message: error.response?.data.message || "",
                    };
                    return this.uploadFilesErrorResponse;
                }

                // if (res && res.status === 200) {
                //     // save file data to cms6 db too to have it for media library
                //     this.saveFileDataToCMS6DB(this.filesToUpload["agenda-packet"]);
                // }
            }

            if (this.filesToUpload["meeting-minutes"]) {
                const formDataMeetingMinutes = new FormData();
                formDataMeetingMinutes.append("file", this.filesToUpload["meeting-minutes"]);
                try {
                    await this.axios.post(this.heyGovMeetRepoApi + meeting_id + "/upload-meeting-minutes-file", formDataMeetingMinutes, {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    });
                } catch (error) {
                    this.uploadFilesErrorResponse.agendaErrorResponse = {
                        type: "meeting minutes",
                        status: error.response?.status || 500,
                        message: error.response?.data.message || "",
                    };
                    return this.uploadFilesErrorResponse;
                }

                // if (res && res.status === 200) {
                //     // save file data to cms6 db too to have it for media library
                //     this.saveFileDataToCMS6DB(this.filesToUpload["meeting-minutes"]);
                // }
            }

            if (this.filesToUpload["additional-file"]) {
                const formDataAdditionalFile = new FormData();
                formDataAdditionalFile.append("file", this.filesToUpload["additional-file"]);
                try {
                    await this.axios.post(this.heyGovMeetRepoApi + meeting_id + "/upload-additional-file", formDataAdditionalFile, {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        // headers: this.heyGovHeaders
                        headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
                        params: { apiKey: this.user.data.heygov_api_key, api: 1 },
                    });
                } catch (error) {
                    this.uploadFilesErrorResponse.agendaErrorResponse = {
                        type: "additional File",
                        status: error.response?.status || 500,
                        message: error.response?.data.message || "",
                    };
                    return this.uploadFilesErrorResponse;
                }

                // if (res && res.status === 200) {
                //     // save file data to cms6 db too to have it for media library
                //     this.saveFileDataToCMS6DB(this.filesToUpload["additional-file"]);
                // }
            }

            this.spinner = false;
            this.$emit("repositorySaved");

            this.axios.post(
                this.cms6Url + `clients/regenerate/meetings`,
                {},
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );
        },
        async saveFileDataToCMS6DB(file, image = false, namespace) {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("file_data_only", true);

            if (image) {
                formData.append("image", true);
            }

            this.prepareForFetch(namespace);
            return await this.axios.post(this.cms6Url + "media", formData, {
                headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
            });
        },
        /*=====  End of FILE UPLOAD  ======*/
    },
    mixins: [userMixin, siteMixin],
};
