<!-- Modal -->
<template>
    <div class="modal fade" id="createMeetingRepositoryModal" ref="createMeetingRepositoryModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-flex">
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Meeting</h5>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ repository.title }}" meeting repository</h5>
                                <p class="col-12 mb-0">Edit the contents of this meeting repository</p>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="editing && !repository.meeting_date">
                    Loading meeting repository details
                    <i class="fa fa-spinner fa-spin"></i>
                </p>

                <form v-show="!editing || (editing && repository && repository.meeting_date)" @submit.prevent.stop="storeNewMeetingRepository">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Repository is not saved.</div>
                        <div class="row">
                            <div class="col">
                                <div class="column">
                                    <div class="col-12 modal-input-item">
                                        <div class="form-group mb-3">
                                            <label for="meeting-title" class="mb-2">Meeting name</label>
                                            <input type="text" class="form-control" v-model="repository.title" required id="meeting-title" placeholder="Enter meeting name" />
                                        </div>
                                    </div>
                                    <div class="col-12 modal-input-item py-2">
                                        <div class="form-group mb-3">
                                            <label for="dp-input-meeting-date" class="mb-2">Date</label>
                                            <Datepicker uid="meeting-date" v-model="repository.meeting_date" required placeholder="Select date" :format="format" :enableTimePicker="false" :autoApply="true" weekStart="0" />
                                        </div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="meeting-category" class="mb-2">Category</label>
                                        <select :class="['form-control', { requiredSelect: !isCategorySelected }]" aria-hidden="false" v-model="selectedCategoryId" id="meeting-category">
                                            <option class="select-placeholder" value="null" disabled hidden selected>Select category</option>
                                            <option v-for="category in categories" :label="category.name" :selected="selectedCategoryId === category.id" :value="category.id" :key="category.id">{{ category.name }}</option>
                                        </select>
                                        <p v-if="!isCategorySelected" :class="!isCategorySelected ? 'requiredSelect-msg' : ''">Category is required</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer border-bottom p-3" :class="mediaLibOpened ? 'col-8' : 'col-12'">
                        <div class="tw-checkbox col-12 d-flex align-items-center">
                            <input id="tw-add-event-auto" type="checkbox" v-model="repository.tw_add_event" />
                            <label class="fw-bold" for="tw-add-event-auto">Add to Calendar</label>
                            <div v-if="repository.tw_add_event" class="d-flex">
                                <Datepicker class="tw-add-event-auto-time ms-1" :format="formatTime" hideInputIcon v-model="eventStartTime" :timePicker="true" :is24="false" placeholder="Start time" :required="true" />
                                <Datepicker class="tw-add-event-auto-time ms-1" :format="formatTime" hideInputIcon v-model="eventEndTime" :timePicker="true" :is24="false" placeholder="End time" :required="true" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer-buttons p-3 text-end">
                        <button class="btn tw-red-color btn-outline-danger rounded-pill btn-lg" type="button" @click="modal.hide()">Cancel</button>
                        <button class="btn tw-red-bg-color text-white ms-2 rounded-pill btn-lg">
                            Create a meeting
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap";
import wordpressMeetingRepositoryMixin from "@/mixins/wordpressMeetingRepositoryMixin";
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import helperMixin from "@/mixins/helperMixin";
import userMixin from "@/mixins/userMixin";
import heyGovMixin from "@/mixins/heyGovMixin";
import wordpressMixin from "@/mixins/wordpressMixin";
import router from "../../router";

let site = localStorage.getObject("site");

let mixins;

if ((site.type == "wordpress" && site.supportsHeyGovMeetRepo) || site.type == "cms6") {
    mixins = [heyGovMeetingRepositoryMixin, helperMixin, heyGovMixin, userMixin];
} else {
    mixins = [wordpressMeetingRepositoryMixin, helperMixin, heyGovMixin, userMixin, wordpressMixin];
}

export default {
    name: "CreateMeetingRepository",
    components: {},
    emits: ["modalHidden", "repositorySaved"],
    data: () => ({
        modal: null,
        subject: "",
        message: "",
        spinner: false,
        uploadSpinner: false,
        repository: {},
        tempRepository: {},
        title: "",
        selectedCategoryId: null,
        isCategorySelected: true,
        format: "LLL dd, yyyy",
        formatTime: "hh:mm a",
        categories: null,
        twd_repository_cat: null, //vm.meetingRepository.twd_repository_cat[0].id
        type: null,
        addEventAuto: false,
        mediaLibOpened: false,
        event: {},
        eventStartTime: null,
        eventEndTime: null,
        sendError: false,
        heyGovVenues: [],
        heyGovBlockedVenues: [],
        heyGovEvent: null,
    }),
    mounted() {
        this.modal = new Modal(this.$refs.createMeetingRepositoryModal);

        let createMeetingRepositoryModal = document.getElementById("createMeetingRepositoryModal");
        let self = this;
        createMeetingRepositoryModal.addEventListener("hidden.bs.modal", function () {
            console.log("closing");
            self.$emit("modalHidden");
            self.repository = {};
            self.mediaLibOpened = false;
        });
        this.heyGovJurisdiction();
    },
    watch: {
        editingMeetingRepositoryId() {
            if (this.editingMeetingRepositoryId) {
                this.setRepository();
            }
        },
        selectedCategoryId() {
            this.isCategorySelected = true;
        },
    },
    computed: {
        hasHeyGovWithVenues() {
            return this.isHeyGovClientWithVenues();
        },
        siteType() {
            return this.getSiteType();
        },
    },
    methods: {
        showModal: function (value) {
            if (value) {
                this.filesToUpload = [];
                this.modal.show();
                this.setCategories();
            }
            if (this.hasHeyGovWithVenues) {
                // cleaning heygov venues array
                this.heyGovVenues = [];
                this.setHeyGovVenues();
            }
        },
        async setCategories() {
            let res = await this.getMeetingRepositoryCategories();
            this.categories = res.data;
        },
        handleMediaLibOpened() {
            this.mediaLibOpened = !this.mediaLibOpened;
        },
        mediaLib(event, type = null) {
            this.type = type;
            if (this.mediaLibOpened) {
                this.mediaLibOpened = false;
            } else {
                let allowMulti = false;
                let requestingFile = true;
                let chooseFeaturedImg = false;
                this.$refs.mediaLibrary.mediaLibOpen(true, requestingFile, allowMulti, chooseFeaturedImg);
                this.mediaLibOpened = true;
            }
        },

        async setHeyGovVenues() {
            let res = await this.getHeyGovVenues();
            let self = this;

            res.forEach(function (venue) {
                let tmp = {};

                tmp.value = venue.id;
                tmp.label = venue.name;

                self.heyGovVenues.push(tmp);
            });
        },
        async storeNewMeetingRepository() {
            if (!this.selectedCategoryId) {
                this.isCategorySelected = false;
                return;
            }

            this.spinner = true;
            let catTemp = [];
            catTemp.push(this.repository.twd_repository_cat);

            this.repository.twd_repository_cat = catTemp;
            this.repository.meeting_date = this.moment(this.repository.meeting_date).format("YYYY-MM-DD");
            this.repository.status = "publish";
            this.repository.comment_status = "closed";
            this.repository.ping_status = "closed";
            this.repository.twd_repository_cat[0] = this.selectedCategoryId;

            let result = await this.saveRepository(this.repository);

            if (result.status === 201 || result.status === 200) {
                this.modal.hide();
                if (result?.data?.id) router.push("/meeting-repository/edit-meeting-repository/" + result.data.id);

                if (this.repository.tw_add_event) {
                    this.buildEvent();
                    if (!this.hasHeyGovEvents) {
                        this.saveEvent(this.event);
                    } else {
                        this.saveHeyGovEvent(this.heyGovEvent);
                    }
                }
                if (this.siteType == "wordpress") {
                    this.spinner = false;
                    this.modal.hide();
                    this.$emit("repositorySaved");
                }
            } else {
                this.sendError = true;
            }
        },
        async setRepository() {
            let res = await this.getMeetingRepository(this.$props.editingMeetingRepositoryId);
            this.repository = await res.data;

            this.repository.title = this.decodeHTMLEntities(this.repository.title.rendered);
            this.repository.twd_repository_cat = this.repository.twd_repository_cat[0];
            this.repository.tw_add_event = false;
            // customize date string by adding time (if there is time already remove it and return only date in format 2022-08-16) to fix JS time zone handler, for example when showing 2022-08-15 if meeting date is 2022-08-16
            this.repository.meeting_date = this.sliceString(this.repository.meeting_date, 10) + " 00:00";
        },
        buildEvent() {
            if (!this.hasHeyGovEvents) {
                this.event.title = this.repository.title;
                this.event.description = this.repository.notes;
                this.event.start_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.event.start_date = this.event.start_date + this.moment(this.eventStartTime).format("HH:mm:ss");
                // this.event.start_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format('YYYY-MM-DD HH:mm:ss') : this.moment().format('YYYY-MM-DD HH:mm:ss');
                this.event.end_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.event.end_date = this.event.end_date + this.moment(this.eventEndTime).format("HH:mm:ss");
                // this.event.end_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss') : this.moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                this.event.status = "publish";
                this.event.categories = [0];
                this.event.all_day = false;
                this.event.end = null;
                this.event.id = null;
                this.event.recurrence = null;
                if (this.hasHeyGovWithVenues) {
                    this.event.heygov_venues = this.heyGovBlockedVenues.join();
                }
            } else {
                this.heyGovEvent = {
                    name: this.repository.title,
                    description: this.repository.notes || "",
                    metadata: { hide_end_time: false },
                };
                this.heyGovEvent.starts_at_local = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.heyGovEvent.starts_at_local = this.heyGovEvent.starts_at_local + this.moment(this.eventStartTime).format("HH:mm:ss");
                this.heyGovEvent.ends_at_local = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.heyGovEvent.ends_at_local = this.heyGovEvent.ends_at_local + this.moment(this.eventEndTime).format("HH:mm:ss");

                if (this.hasHeyGovWithVenues) {
                    this.heyGovEvent.venues = this.heyGovBlockedVenues;
                }
            }
        },
        async saveHeyGovEvent(event) {
            // Create new HG event
            let res = await this.axios.post(`${this.apiRoute}/${this.domain}/events?apiKey=${this.apiKey}`, event);
            if (res && res.status === 200) {
                await this.axios.post(
                    this.cms6Url + `clients/regenerate/events`,
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        headers: this.headers,
                    }
                );
            }
        },
    },
    props: {
        editing: Boolean,
        editingMeetingRepositoryId: [Number, String],
        list: Array,
    },
    mixins: mixins,
};
</script>

<style scoped>
.tw-create-meeting-repository-modal-upload-main a {
    word-break: keep-all;
    white-space: nowrap;
}
.tw-create-meeting-repository-modal-upload-main span.tw-close-icon {
    right: -20px;
}
.tw-checkbox label {
    margin-left: 35px;
    font-size: 14px;
}
.tw-checkbox label::before {
    top: calc(50% - 12px);
    left: -35px;
}
.tw-checkbox label::after {
    top: calc(50% - 6px);
    left: -29px;
}
.tw-add-event-auto-time {
    max-width: 120px;
}
.modal .modal-dialog .modal-content .modal-body label,
#tw-featured-image-block label {
    font-size: 16px;
}
.form-control {
    font-size: 14px !important;
}
.modal-dialog.modal-md {
    max-width: 405px;
    top: 25%;
}
.modal-footer-buttons button {
    font-size: 14px;
}
.modal-footer > * {
    margin: unset;
}
button.btn-outline-danger:hover {
    color: #fff !important;
}
.datepicker-input-item #dp-input-meeting-date {
    border-radius: 12px !important;
    height: 45px;
}
.modal .modal-dialog .modal-content .modal-header h5 {
    font-size: 18px;
}
.form-control.requiredSelect {
    border: 2px solid red;
}
.form-group .requiredSelect-msg {
    font-size: 0.8rem;
    text-align: end;
    color: red;
    padding-top: 5px;
    margin-right: 5px;
}
@media only screen and (min-width: 992px) {
    .tw-custom-col-lg-4 .col-lg-4:first-child {
        padding-right: 4px;
    }
    .tw-custom-col-lg-4 .col-lg-4:nth-child(2) {
        padding-right: 2px;
        padding-left: 2px;
    }
    .tw-custom-col-lg-4 .col-lg-4:last-child {
        padding-left: 4px;
    }
}
</style>
