<template>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-body" id="modal-body">
                <div class="modal-list">
                    <nested-draggable @drag="onDrag" :list="agendaItems" :editMode="editMode" @end="onMoveEnd" @itemDelete="odAgendaItemDelete" :repository="meetingRepo" />
                    <div v-if="addNewAgendaItem" class="new-agenda-item list-group-item agenda-list-item mx-2">
                        <input class="form-control" v-model="newAgendaItemTitle" type="text" />
                        <span @click="saveNewItem" :class="{ disabled: newAgendaItemTitle === '', 'btn fa-stack icon-btn': true }">
                            <i class="tw-light-red-color fa fa-circle fa-stack-2x"></i>
                            <i class="fas fa-solid fa-check fa-stack-1x fa-inverse"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-3">
                <div v-if="editMode" class="addItem">
                    <button :class="{ disabled: addNewAgendaItem, 'btn tw-dark-grey-bg-color text-white': true }" type="button" @click="addNewAgendaItem = true">Add item</button>
                </div>
                <button v-if="!editMode" class="btn tw-dark-grey-bg-color text-white" @click="editAgendaList" type="button">Edit</button>
                <button v-if="editMode" class="btn tw-red-bg-color text-white" @click="saveAgendaList" type="button">Save changes</button>
                <button v-if="!editMode" class="btn tw-red-bg-color text-white" type="button" @click="handleAgendaApprove">Approve and proceed</button>
            </div>
        </div>
    </div>
</template>
<script>
import wordpressMixin from "@/mixins/wordpressMixin";
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import nestedDraggable from "./AgendaList/NestedList.vue";
import router from "../../router";
import { mapState } from "vuex";

export default {
    name: "EditAgendaListItems",
    mixins: [wordpressMixin, heyGovMeetingRepositoryMixin],
    props: ["list", "meetingRepo"],
    components: { nestedDraggable },
    emits: ["update-items-list", "update:meetingRepo", "proceed-to-transcript", "setParentRepo", "meetingRepo"],
    data: () => ({
        modal: null,
        spinner: false,
        repository: [],
        agendaItems: [],
        editMode: false,
        newAgendaItemTitle: "",
        newAgendaItem: {},
        addNewAgendaItem: false,
    }),
    mounted() {
        if (!this.subscribedToMinutes) {
            router.push("/meeting-repository/edit-meeting-repository/" + repoId);
        } else if (!this.meetingRepo.agenda || this.list.length === 0) {
            router.push("/meeting-repository/edit-meeting-repository/" + repoId);
            this.$emit("agendaItemsNotPresent");
        }
        if (this.list.length > 0) this.setInitialAgendaList(this.list);

        const repoId = this.$route.params.id;

        if (!this.subscribedToMinutes || this.list.length === 0) router.push("/meeting-repository/edit-meeting-repository/" + repoId);
    },
    computed: {
        ...mapState(["subscribedToMinutes"]),
    },
    methods: {
        handleBeforeUnload(event) {
            event.preventDefault();
            setTimeout(() => this.$router.back(), 1000);
        },
        setInitialAgendaList(listItems) {
            const list = this.getAndSortAgendaItemsAndSubitems(listItems)
                .map((item) => {
                    item.edit = false;
                    if (item.parent_id === 0) return item;
                })
                .filter((item) => {
                    return item !== undefined;
                });

            this.agendaItems = list || [];
        },
        getAndSortAgendaItemsAndSubitems(agendaItems) {
            const subitems = agendaItems.filter((agendaItem) => {
                // init empty array, since API is not providing it if there is no subitems
                agendaItem.subitems = [];
                return agendaItem.parent_id && agendaItem;
            });
            const subitemsParentIds = subitems.map((subitem) => subitem.parent_id);
            const removedParenIdsDuplicates = subitemsParentIds.filter((item, index) => subitemsParentIds.indexOf(item) === index);

            const objectsWithChildren = agendaItems.filter((agendaItem) => {
                if (removedParenIdsDuplicates.indexOf(agendaItem.id) !== -1) {
                    subitems.forEach((subitem) => {
                        if (subitem.parent_id === agendaItem.id) agendaItem.subitems.push(subitem);
                    });
                    return agendaItem;
                } else {
                    return agendaItem.parent_id === 0 || removedParenIdsDuplicates.indexOf(agendaItem.parent_id) === -1;
                }
            });

            return objectsWithChildren.map((item, parentIndex) => {
                return {
                    ...item,
                    order: parentIndex + 1,
                    subitems:
                        item.subitems !== undefined
                            ? item.subitems.map((subitem, childIndex) => {
                                  return { ...subitem, order: childIndex + 1 };
                              })
                            : [],
                };
            });
        },
        setAgendaItems(newItem) {
            const list = this.agendaItems.map((item) => {
                item.edit = false;
                return item;
            });
            if (Object.keys(newItem).length !== 0 || !newItem) list.push(newItem);
            this.agendaItems = list;
        },
        closeModal() {
            this.editMode = false;
            this.addNewAgendaItem = false;
            this.modal.hide();
        },
        showModal(value) {
            if (!value) return;
            this.setAgendaItems(this.newAgendaItem);
        },
        cancelEdit(agendaItem) {
            Object.assign(agendaItem, this._originalItem);
            agendaItem.edit = false;
        },
        editAgendaList() {
            this.editMode = true;
        },
        onMoveEnd() {
            // Limit subitems level to one.
            this.agendaItems.forEach((item, index) => {
                if (!item.subitems) return;
                item.subitems.forEach((subitem) => {
                    if (subitem.subitems.length > 0) {
                        subitem.subitems.forEach((subitem1, subitem1Index) => {
                            subitem.subitems.splice(subitem1Index, 1);
                            this.agendaItems[index].subitems.push(subitem1);
                            if (subitem1.subitems.length > 0) {
                                subitem1.subitems.forEach((subitem2, subitem1Index) => {
                                    subitem1.subitems.splice(subitem1Index, 1);
                                    this.agendaItems[index].subitems.push(subitem2);
                                    if (subitem2.subitems.length > 0) {
                                        subitem2.subitems.forEach((subitem3, subitem1Index) => {
                                            subitem2.subitems.splice(subitem1Index, 1);
                                            this.agendaItems[index].subitems.push(subitem3);
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            });
        },
        onDrag() {},
        saveAgendaList() {
            const list = this._setParentIds();
            this.$emit("setParentRepo", this.meetingRepo);
            this.addNewAgendaItem = false;
            this.editMode = false;

            let orderedList = [];
            list.forEach((item) => {
                if (item.subitems.length > 0) {
                    item.subitems.forEach((subitem, index) => {
                        orderedList.push({
                            id: subitem.id,
                            parent_id: subitem.parent_id,
                            order: list.length + index + 1,
                        });
                    });
                }
                orderedList.push({
                    id: item.id,
                    parent_id: item.parent_id,
                    order: item.order,
                });
            });

            this.setAgendaItemsOrder(this.meetingRepo, orderedList).then(() => {
                this.getMeetingRepository(this.meetingRepo.id).then((response) => {
                    this.agendaItems = this.getAndSortAgendaItemsAndSubitems(response.data.agenda_items);
                    this.repository = response.data;
                });
            });
        },
        odAgendaItemDelete(agendaItem, index) {
            if (!agendaItem) return;
            if (agendaItem.parent_id > 0) {
                const parentIndex = this._getParentIndex(agendaItem);
                this.agendaItems[parentIndex].subitems.splice(index, 1);
            } else {
                this.agendaItems.splice(index, 1);
            }
            this.deleteAgendaItem(this.meetingRepo, agendaItem);
        },
        saveNewItem() {
            const repository = this.meetingRepo;
            this.newAgendaItem = {
                id: null,
                title: this.newAgendaItemTitle,
                status: "ok",
                parent_id: 0,
                order: repository.agenda_items.length + 1,
                text: "",
                meeting_id: repository.id,
                subitems: [],
            };

            this.createAgendaItem(repository, this.newAgendaItem)
                .then((response) => {
                    this.newAgendaItemTitle = "";
                    this.addNewAgendaItem = false;
                    this.setAgendaItems({
                        ...this.newAgendaItem,
                        id: response.data.id,
                    });
                })
                .catch((error) => {
                    console.error("New item is not added.", error);
                });
        },
        handleAgendaApprove() {
            let newRepository = this.meetingRepo;
            newRepository.agenda_items = this.agendaItems;
            this.$emit("update:meetingRepo", newRepository);
            this.$emit("proceed-to-transcript");
        },

        _getParentIndex(agendaItem) {
            let parentIndex;
            this.agendaItems.forEach((item, index) => {
                if (item.id === agendaItem.parent_id) {
                    parentIndex = index;
                    return;
                }
            });
            return parentIndex;
        },
        _setParentIds() {
            let list = [];
            this.agendaItems.forEach((element) => {
                if (element.subitems && element.subitems.length > 0) {
                    element.subitems.forEach((subitem) => {
                        subitem.parent_id = element.id;
                    });
                } else {
                    element.parent_id = 0;
                }
                list.push(element);
            });
            return list;
        },
        _flatItemsArray(array) {
            let flatArray = [];
            array.forEach((item) => {
                if (item.subitems.length > 0) {
                    flatArray = flatArray.concat(item.subitems);
                } else {
                    flatArray.push(item);
                }
            });
            return flatArray;
        },
    },
};
</script>
<style scoped>
input {
    max-width: 92%;
}
.new-agenda-item {
    position: relative;
}
.new-agenda-item input {
    max-width: 100%;
}
.new-agenda-item .icon-btn {
    position: absolute;
    bottom: 11px;
    right: 15px;
}
.parent-item,
.list-group-subitem {
    display: flex;
    flex-direction: row;
}
.list-group-item.agenda-list-item {
    display: flex;
    flex-direction: column;
    position: relative;
    border: none;
}
.edit-save-icons {
    position: absolute;
    top: 50%;
    left: 96%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
}
.edit-save-icons .icon-btn:active {
    transform: scale(0.93);
}
.sortable-chosen .drag-and-drop-item {
    cursor: grab;
}
.he-tree .tree-line {
    background-color: aquamarine;
}
.drag-and-drop-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#agendaListModal.modal .modal-dialog .modal-content .modal-footer button.btn {
    width: auto;
}
</style>
