<template>
    <section id="meeting-transcript">
        <div v-if="status.transcriptionInProgress" class="overlay"></div>
        <div v-if="status.isPageIsLoaded" class="no-recording-msg p-5 text-center">
            <div>
                <span class="spinner"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
        </div>
        <div v-else class="row">
            <div class="content col-8">
                <div class="speakers"></div>
                <div class="transcript-lines px-3 py-2">
                    <div v-if="transcript.length === 0" class="no-recording-msg p-5 text-center">
                        <div>
                            <h3>This meeting doesn't have a recording yet</h3>
                            <p>Upload a recording on the right side 👉</p>
                        </div>
                    </div>
                    <div v-else class="transcript-line py-2 my-2" v-for="(transcriptLine, key) in transcript" :key="key">
                        <div class="p-2">
                            <div class="speaker-time px-2 pb-3">
                                <span>
                                    <span class="me-1 speaker-icon">
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13" cy="13" r="13" fill="#DFEBF6" />
                                            <path
                                                d="M13 5.57144C12.1659 5.57144 11.3659 5.92764 10.776 6.56168C10.1862 7.19571 9.85484 8.05565 9.85484 8.95232C9.85484 9.84898 10.1862 10.7089 10.776 11.343C11.3659 11.977 12.1659 12.3332 13 12.3332C13.8341 12.3332 14.6341 11.977 15.224 11.343C15.8138 10.7089 16.1452 9.84898 16.1452 8.95232C16.1452 8.05565 15.8138 7.19571 15.224 6.56168C14.6341 5.92764 13.8341 5.57144 13 5.57144ZM9.64516 14.1363C8.81101 14.1363 8.01103 14.4925 7.4212 15.1266C6.83136 15.7606 6.5 16.6205 6.5 17.5172V18.5883C6.5 19.268 6.95794 19.8468 7.58194 19.9559C11.1699 20.5861 14.8301 20.5861 18.4181 19.9559C18.7198 19.9026 18.9942 19.7358 19.1923 19.4854C19.3905 19.2349 19.4995 18.917 19.5 18.5883V17.5172C19.5 16.6205 19.1686 15.7606 18.5788 15.1266C17.989 14.4925 17.189 14.1363 16.3548 14.1363H16.0697C15.9131 14.1369 15.761 14.1628 15.6134 14.2139L14.8871 14.469C13.6609 14.8993 12.3391 14.8993 11.1129 14.469L10.3866 14.2139C10.2394 14.1631 10.0858 14.1369 9.93116 14.1363H9.64516Z"
                                                fill="#4E32A4"
                                            />
                                        </svg>
                                    </span>
                                    Speaker {{ transcriptLine.speaker + 1 }}
                                </span>
                                <span class="timestamp">{{ _convertTimestamp(transcriptLine.timestamp) }}</span>
                            </div>
                            <div class="line-text px-2">
                                {{ transcriptLine.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMediaUploaded && !status.isUploadInProgress" class="media-upload col-4">
                <div class="mb-3 video-wrapper">
                    <div class="ratio ratio-16x9 text-center">
                        <div class="d-flex justify-content-center align-items-center file-drop">
                            <div>
                                <p class="mb-3 text-neutral-500">
                                    <small>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="video" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="me-1 svg-inline--fa fa-video">
                                            <path
                                                fill="currentColor"
                                                d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
                                                class=""
                                            ></path>
                                        </svg>
                                        Meeting recording</small
                                    >
                                </p>
                                <p class="my-0">
                                    <label for="meeting-audio-video-file" class="d-inline-block btn btn-sm btn-outline-primary">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-import" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="me-1 svg-inline--fa fa-file-import">
                                            <path
                                                fill="currentColor"
                                                d="M128 64c0-35.3 28.7-64 64-64L352 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64l-256 0c-35.3 0-64-28.7-64-64l0-112 174.1 0-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39L128 288l0-224zm0 224l0 48L24 336c-13.3 0-24-10.7-24-24s10.7-24 24-24l104 0zM512 128l-128 0L384 0 512 128z"
                                                class=""
                                            ></path>
                                        </svg>
                                        Upload audio or video
                                    </label>
                                </p>
                                <small class="d-block my-2 text-neutral-300">or</small>
                                <button data-bs-toggle="modal" data-bs-target="#modal-recording-url" class="btn btn-sm btn-outline-primary">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-plus">
                                        <path
                                            data-v-5e9bfc60=""
                                            fill="currentColor"
                                            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"
                                            class=""
                                        ></path>
                                    </svg>
                                    Add YouTube link
                                </button>
                                <input type="file" id="meeting-audio-video-file" accept="audio/*,video/*" class="d-none" @change="handleFileUpload" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="uploaded-media col-4">
                <div v-if="status.isUploadInProgress" class="mb-3 video-wrapper">
                    <div class="ratio ratio-16x9 text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center file-drop">
                            <h3>{{ uploadProgress }}%</h3>
                        </div>
                        <div class="progress-bg-color" :style="{ width: uploadProgress + `%` }"></div>
                    </div>
                </div>

                <div v-else class="video-wrapper text-end mb-3">
                    <div class="uploaded-video-wrapper">
                        <button v-if="isRemoveButtonEnabled" @click="handleRecordingRemove" class="btn my-2 px-3 tw-red-bg-color text-white rounded-pill btn-sm">Remove recording</button>
                        <div class="ratio ratio-16x9 text-center">
                            <div class="d-flex flex-column justify-content-center align-items-center file-drop">
                                <video v-if="media.video_file_url" controls preload="metadata" :src="_getMediaFileUrl(media.video_file_url)" class="ratio ratio-16x9">
                                    Your browser can't play this video format. You can
                                    <a :href="_getMediaFileUrl(media.video_file_url)" download> download the video </a>
                                    and watch it directly on your computer.
                                </video>
                                <audio v-else-if="media.audio_file_url" controls preload="metadata" :src="_getMediaFileUrl(media.audio_file_url)" class="w-100">
                                    Your browser can't play this audio format. You can
                                    <a :href="_getMediaFileUrl(media.audio_file_url)" download> download the audio </a>
                                    and listen directly on your computer.
                                </audio>
                            </div>
                        </div>
                        <div v-if="!status.transcriptionInProgress && status.transcriptJobStatus === 'transcribed'" class="text-center">
                            <button @click="handleApproveAndProceed" class="btn my-4 px-3 btn-primary btn-xl">✨ Approve and proceed to generate minutes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-recording-url" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title my-0">Upload recording by link</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addRecordingByUrl">
                            <p>If you have a recording on uploaded YouTube, paste the link below.</p>

                            <p>ℹ️ The recording link needs to be public, not behind a password.</p>

                            <div class="form-row mb-4">
                                <label class="form-label" for="recording-url"> Recording URL </label>

                                <input type="url" class="form-control" id="recording-url" v-model="recordingUrl" required placeholder="https://www.youtube.com/watch?v=..." />
                            </div>

                            <p class="card-text text-center pb-3">
                                <button class="btn btn-primary">Upload recording</button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="status.transcriptionInProgress" class="spinner">
        <span class="ms-2">
            <i class="fa fa-spinner fa-spin fa-lg"></i>
        </span>
        <h3 class="py-3">Transcribing is in progress...</h3>
    </div>
</template>

<script>
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import axios from "axios";
import { mapState } from "vuex";
import router from "../../router";
import moment from "moment";
import { Modal } from "bootstrap";

const GET_AI_TRANSCRIPT_DELAY = 8000;

export default {
    name: "MeetingTranscript",
    props: ["list", "meetingRepo", "update:meetingRepo"],
    emits: ["modalHidden", "repositorySaved", "showClerkMinutesPopup", "setParentRepo", "proceed-to-minutes", "agenda-removed", "proceed-to-transcript", "agendaItemsNotPresent", "mediaFileIsNotPresent"],
    components: {},
    mixins: [heyGovMeetingRepositoryMixin],
    data: () => ({
        isMediaUploaded: false,
        speakers: [],
        transcript: [],
        uploadProgress: 0,
        transcriptionStatusTimer: null,
        recordingUrl: "",
        isRemoveButtonEnabled: false,
        repoId: null,
        status: {
            transcriptJobStatus: "",
            transcriptionInProgress: false,
            isPageIsLoaded: false,
            isUploadInProgress: false,
        },
        media: {
            audio_file_url: null,
            video_file_url: null,
        },
        repository: [],
    }),
    mounted() {
        this.repoId = router.currentRoute?._value.params.id;
        this.status.isPageIsLoaded = true;

        this.getMeetingRepository(this.repoId).then((response) => {
            this.status.transcriptionInProgress = true;
            this.repository = response.data;
            this.status.isPageIsLoaded = false;

            if (!this.repository.video_file_url || !this.repository.audio_file_url) {
                this.status.transcriptionInProgress = false;
            }

            if (!this.subscribedToMinutes) {
                router.push("/meeting-repository/edit-meeting-repository/" + this.repoId);
            } else if (!response.data.agenda || response.data.agenda_items.length === 0) {
                router.push("/meeting-repository/edit-meeting-repository/" + this.repoId);
                this.$emit("agendaItemsNotPresent");
            }

            if (this.repository.transcript_job_status === "transcribed") {
                this.isMediaUploaded = true;
                this.isRemoveButtonEnabled = true;
                this._getMeetingTranscript(this.repository);
                this.status.transcriptionInProgress = false;
                this.status.isUploadInProgress = false;
                this.status.transcriptJobStatus = "transcribed";
                this.media.audio_file_url = this.repository.audio_file_path;
                this.media.video_file_url = this.repository.video_file_path;
            }
            if (this.repository.length > 0) return;
            this.$emit("setParentRepo", { ...response.data, title: response.data?.title?.rendered });
        });

        this.$modalRecordingUrl = new Modal(document.getElementById("modal-recording-url"));
    },
    computed: {
        ...mapState(["subscribedToMinutes"]),
    },
    methods: {
        async handleFileUpload(event) {
            const file = event.target.files[0];

            if (!file.type.startsWith("audio/") && !file.type.startsWith("video/")) {
                console.error("Only audio or video files are allowed 🤷");
                return;
            } else if (file.size < 1024 * 1024 * 5) {
                console.error("File is too small to be a meeting recording 🤷");
                return;
            } else if (file.size > 1024 * 1024 * 1024 * 5) {
                console.error("File is too big 😬 please compress it to be under 5GB");
                return;
            }

            const response = await this.uploadAudioVideoFile(this.repository, file);
            this.status.isUploadInProgress = true;

            if (response) {
                const updatedFields = {
                    transcript_job_status: "started",
                };

                this.isMediaUploaded = true;
                if (file.type.startsWith("audio/")) {
                    updatedFields.audio_file_path = response.data.path;
                } else {
                    updatedFields.video_file_path = response.data.path;
                }

                await axios.put(response.data.uploadUrl, file, {
                    doNotAddClient: true,
                    headers: { "Content-Type": file.type },
                    onUploadProgress: (progress) => {
                        this.uploadProgress = ((progress.loaded / progress.total) * 100).toFixed(0);

                        if (this.uploadProgress > 99.9) {
                            this.isRemoveButtonEnabled = true;
                            this.status.isUploadInProgress = false;
                            this.status.transcriptionInProgress = true;
                            if (file.type.startsWith("audio/")) {
                                this.media.audio_file_url = response.data.path;
                            } else {
                                this.media.video_file_url = response.data.path;
                            }
                        }
                    },
                });

                this._updateMeeting(updatedFields, this.repository);
            }
        },

        addRecordingByUrl() {
            this.recordingUrl = this.recordingUrl.trim();

            if (!URL.canParse(this.recordingUrl)) {
                this.$swal({
                    title: "Invalid URL!",
                    icon: "error",
                });
                return;
            }

            const parsedUrl = new URL(this.recordingUrl);

            const isYoutubeUrl = ["youtube.com", "www.youtube.com", "youtu.be"].includes(parsedUrl.hostname);
            const isVimeoUrl = parsedUrl.hostname.endsWith("vimeo.com");
            const isZoomUrl = parsedUrl.hostname.endsWith("zoom.us");

            if (!isYoutubeUrl && !isVimeoUrl && !isZoomUrl) {
                this.$swal({
                    title: "Not supported!",
                    text: "Only YouTube, Vimeo and Zoom recordings are supported.",
                    icon: "warning",
                });
                return;
            }

            const fields = {
                video_public_url: this.recordingUrl,
                transcript_job_status: "uploading",
            };
            this.isRemoveButtonEnabled = false;
            this.status.transcriptionInProgress = true;
            this._updateMeeting(fields, this.repository).then(() => {
                this.status.externalUrlProcessing = true;
                this.media.video_file_url = this.recordingUrl;
                this.isMediaUploaded = true;
                this.status.isUploadInProgress = false;
            });

            this.$modalRecordingUrl.hide();
            this.recordingUrl = "";
        },

        async handleRecordingRemove(event) {
            event.preventDefault();
            this.$swal({
                title: "Are you sure?",
                text: "This will remove recording and transcript.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this._removeRecordingAndTranscript()
                        .then(() => {
                            this.$swal({
                                title: "Deleted!",
                                text: "Your recording file has been removed.",
                                icon: "success",
                            });
                        })
                        .catch((error) => {
                            console.error("Recording is not removed.", error);
                        });
                }
            });
        },

        handleApproveAndProceed() {
            router.push("/meeting-repository/edit-meeting-repository/" + this.repoId + "/meeting-minutes");
        },

        async _removeRecordingAndTranscript() {
            this.isMediaUploaded = false;
            this.status.isUploadInProgress = false;
            this.transcript = [];
            this.transcript_job_status = "";
            this.transcriptionInProgress = false;
            this.media.video_file_url = null;
            this.media.audio_file_url = null;

            return await this.deleteRecording(this.repository);
        },

        _getMediaFileUrl(path) {
            if (!path) return;
            return process.env.VUE_APP_HEYGOV_FILES + path;
        },

        _convertTimestamp(timestamp) {
            const momentDuration = moment.duration(timestamp, "seconds");

            const hours = momentDuration.hours();
            const minutes = momentDuration.minutes();
            const seconds = momentDuration.seconds();

            return hours ? `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}` : `${minutes}:${String(seconds).padStart(2, "0")}`;
        },

        async _updateMeeting(data, repository) {
            try {
                const response = await this.updateMeeting(data, repository);
                if (response) {
                    this.transcriptionStatusTimer = setInterval(() => {
                        this.checkRecordingUploadStatus(this.repository.id);
                    }, GET_AI_TRANSCRIPT_DELAY);
                }
                return response;
            } catch {
                console.error("Meeting cannot be updated!");
            }
        },

        async _getMeetingTranscript(repository) {
            try {
                const response = await this.loadMeetingTranscript(repository);
                if (response) this.transcript = response.data;
            } catch {
                console.error("Cannot create transcript!");
            }
        },

        async checkRecordingUploadStatus(id) {
            await this.getMeetingRepository(id).then((response) => {
                if (response.data.transcript_job_status === "transcribed") {
                    clearInterval(this.transcriptionStatusTimer);
                    this.repository.transcriptJobStatus = "transcribed";
                    this.status.transcriptJobStatus = "transcribed";
                    this.status.transcriptionInProgress = false;
                    this._getMeetingTranscript(response.data);
                    if (!this.media.video_file_url) this.media.video_file_url = response.data?.video_file_path;
                    this.status.externalUrlProcessing = false;
                    this.isRemoveButtonEnabled = true;
                    return response.data;
                }
                return;
            });
        },
    },
};
</script>
<style scoped>
#meeting-transcript {
    max-width: 80%;
    margin: 0 auto;
}
.media-upload {
    border: 1px solid #0d6efd;
    border-radius: 10px;
}
.spinner {
    font-size: 5.5rem;
}
.progress {
    position: relative;
}
.file-drop {
    z-index: 2;
}
.progress-bg-color {
    border-radius: 12px;
    position: absolute;
    height: 100%;
    background-color: #e9ecef;
}
.transcript-lines {
    background-color: #fff;
    border-radius: 12px;
}
.transcript-line {
    background: #f8f7f7;
    border-radius: 10px;
}
.speaker-time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.speaker-icon svg {
    margin-top: -5px;
}
.timestamp {
    color: #1877f2;
    text-decoration: underline;
}
.video-wrapper {
    border-radius: 12px;
}
.video-wrapper video {
    border-radius: 12px;
}
.uploaded-video-wrapper {
    position: fixed;
}
.spinner {
    font-size: 5.5rem;
    position: absolute;
    top: 45vh;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 6;
    text-align: center;
}
.spinner span {
    font-size: 5rem;
}
.spinner svg {
    color: #fff;
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    cursor: pointer;
}
</style>
