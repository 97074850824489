<!-- Modal -->
<template>
    <div class="modal fade" id="supportModal" ref="supportModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="row">
                        <h5 class="modal-title col-12" id="modal-title">Contact support</h5>
                    </div>
                </div>
                <form @submit.prevent="send">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Support ticket wasn't sent.</div>
                        <div class="form-group mb-3">
                            <label class="mb-2" for="ticket-subject"> Subject</label>
                            <input type="text" class="form-control" v-model="subject" required id="ticket-subject" placeholder="Request to Subscribe to ClerkMinutes" />
                        </div>

                        <div class="form-group">
                            <label class="mb-2" for="ticket-message"> Message</label>
                            <textarea
                                rows="50"
                                id="ticket-message"
                                class="form-control"
                                v-model="message"
                                required
                                placeholder="Please provide details about your subscription request, including your account email and any specific plan or features you're interested in."
                            ></textarea>
                        </div>
                    </div>

                    <div class="modal-footer-buttons p-3 text-end">
                        <button class="btn tw-red-color btn-outline-danger rounded-pill btn-lg" type="button" @click="closeModal()">Cancel</button>
                        <button class="btn tw-red-bg-color text-white ms-2 rounded-pill btn-lg">
                            Contact support
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap";
import wordpressMixin from "@/mixins/wordpressMixin";
import cms6Mixin from "@/mixins/cms6Mixin";

let site = localStorage.getObject("site");

if (!site) site = { type: "wordpress" };

let mixins;

if (site.type == "wordpress") {
    mixins = [wordpressMixin];
} else if (site.type == "cms6") {
    mixins = [cms6Mixin];
}

export default {
    name: "SupportModal",
    mixins: mixins,
    emits: ["supportContactModalClosed"],
    data: () => ({
        modal: null,
        toast: null,
        subject: "",
        message: "",
        spinner: false,
        sendError: false,
    }),
    mounted() {
        this.modal = new Modal(this.$refs.supportModal);
        let supportModal = document.getElementById("supportModal");
        let self = this;
        supportModal.addEventListener("hidden.bs.modal", function () {
            self.subject = "";
            self.message = "";
        });
    },
    methods: {
        closeModal() {
            this.modal.hide();
            this.$emit("supportContactModalClosed");
        },
        showModal(value) {
            if (value) this.modal.show();
        },
        async send() {
            this.spinner = true;
            let ticket = await this.sendTicket(this.subject, this.message);
            this.sendError = !ticket.data.sent;
            this.spinner = false;
            this.closeModal();
            if (ticket.data.sent) {
                this.$swal({
                    title: "Support message sent!",
                    toast: true,
                    position: "bottom-end",
                    showCloseButton: true,
                    timer: 5000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    icon: "success",
                });
            } else {
                this.$swal({
                    title: "Oh Snap!",
                    toast: true,
                    position: "bottom-end",
                    showCloseButton: true,
                    timer: 5000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    icon: "error",
                    text: "Server error!",
                });
            }
        },
    },
};
</script>
<style scoped>
input,
textarea {
    border-radius: 21.5px;
}
textarea {
    min-height: 10rem;
}
.modal {
    top: 20%;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccd1d8 !important;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccd1d8 !important;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccd1d8 !important;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #ccd1d8 !important;
}
.modal-footer-buttons button {
    font-size: 14px;
}
.modal-footer > * {
    margin: unset;
}
button.btn-outline-danger:hover {
    color: #fff !important;
}
</style>
