<template>
    <section id="meeting-minutes">
        <div v-if="spinner" class="overlay"></div>
        <div class="minutes">
            <div v-if="areMinutesGenerated" class="editor">
                <div class="editor-toolbar my-3 d-flex">
                    <template v-if="editor">
                        <div class="dropdown">
                            <button type="button" class="btn dropdown-toggle" id="editor-line-type" data-bs-toggle="dropdown" aria-expanded="false">
                                <font-awesome-icon :icon="['fas', 'heading']" />
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="editor-line-type">
                                <li>
                                    <button type="button" class="dropdown-item" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'text-primary': editor.isActive('heading', { level: 1 }) }">Heading 1</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'text-primary': editor.isActive('heading', { level: 2 }) }">Heading 2</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'text-primary': editor.isActive('heading', { level: 3 }) }">Heading 3</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'text-primary': editor.isActive('heading', { level: 4 }) }">Heading 4</button>
                                </li>
                            </ul>
                        </div>

                        <button type="button" class="btn" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'btn-primary': editor.isActive('bold') }">
                            <font-awesome-icon :icon="['fas', 'bold']" />
                        </button>
                        <button type="button" class="btn" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'btn-primary': editor.isActive('italic') }">
                            <font-awesome-icon :icon="['fas', 'italic']" />
                        </button>
                        <button type="button" class="btn" @click="editor.chain().focus().toggleUnderline().run()" :disabled="!editor.can().chain().focus().toggleUnderline().run()" :class="{ 'btn-primary': editor.isActive('underline') }">
                            <font-awesome-icon :icon="['fas', 'underline']" />
                        </button>

                        <button type="button" class="btn" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'btn-primary': editor.isActive('strike') }">
                            <font-awesome-icon :icon="['fas', 'strikethrough']" />
                        </button>

                        <span class="toolbar-separator"></span>

                        <button type="button" class="btn" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'btn-primary': editor.isActive('bulletList') }">
                            <font-awesome-icon :icon="['fas', 'list-ul']" />
                        </button>
                        <button type="button" class="btn" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'btn-primary': editor.isActive('orderedList') }">
                            <font-awesome-icon :icon="['fas', 'list-ol']" class="icon alt" />
                        </button>

                        <button type="button" class="btn" @click="editor.chain().focus().setHorizontalRule().run()">
                            <font-awesome-icon :icon="['fas', 'ruler-horizontal']" />
                        </button>

                        <span class="toolbar-separator"></span>

                        <button type="button" class="btn" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                            <font-awesome-icon :icon="['fas', 'undo']" />
                        </button>
                        <button type="button" class="btn" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
                            <font-awesome-icon :icon="['fas', 'redo']" />
                        </button>
                    </template>
                </div>
                <editor-content :class="['editor-content']" :editor="editor" />
            </div>

            <div v-if="areMinutesGenerated" class="control-buttons">
                <div>
                    <button @click="handlePdfDownload" class="my-3 me-3 btn btn-outline-primary">
                        <span class="me-1">
                            <svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 309.267 309.267" xml:space="preserve">
                                <g>
                                    <path style="fill: #e2574c" d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z" />
                                    <path style="fill: #b53629" d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z" />
                                    <path
                                        style="fill: #ffffff"
                                        d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544zM155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"
                                    />
                                </g>
                            </svg>
                        </span>
                        Download PDF
                    </button>
                    <button @click="handleDocxDownload" class="my-3 btn btn-outline-primary">
                        <span class="me-1">
                            <svg width="25px" height="25px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <linearGradient id="a" x1="4.494" y1="-1712.086" x2="13.832" y2="-1695.914" gradientTransform="translate(0 1720)" gradientUnits="userSpaceOnUse">
                                        <stop offset="0" stop-color="#2368c4" />
                                        <stop offset="0.5" stop-color="#1a5dbe" />
                                        <stop offset="1" stop-color="#1146ac" />
                                    </linearGradient>
                                </defs>
                                <title>file_type_word</title>
                                <path d="M28.806,3H9.705A1.192,1.192,0,0,0,8.512,4.191h0V9.5l11.069,3.25L30,9.5V4.191A1.192,1.192,0,0,0,28.806,3Z" style="fill: #41a5ee" />
                                <path d="M30,9.5H8.512V16l11.069,1.95L30,16Z" style="fill: #2b7cd3" />
                                <path d="M8.512,16v6.5L18.93,23.8,30,22.5V16Z" style="fill: #185abd" />
                                <path d="M9.705,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5H8.512v5.309A1.192,1.192,0,0,0,9.705,29Z" style="fill: #103f91" />
                                <path d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z" style="opacity: 0.10000000149011612; isolation: isolate" />
                                <path d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z" style="opacity: 0.20000000298023224; isolation: isolate" />
                                <path d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z" style="opacity: 0.20000000298023224; isolation: isolate" />
                                <path d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z" style="opacity: 0.20000000298023224; isolation: isolate" />
                                <path d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z" style="fill: url(#a)" />
                                <path
                                    d="M6.9,17.988c.023.184.039.344.046.481h.028c.01-.13.032-.287.065-.47s.062-.338.089-.465l1.255-5.407h1.624l1.3,5.326a7.761,7.761,0,0,1,.162,1h.022a7.6,7.6,0,0,1,.135-.975l1.039-5.358h1.477l-1.824,7.748H10.591L9.354,14.742q-.054-.222-.122-.578t-.084-.52H9.127q-.021.189-.084.561c-.042.249-.075.432-.1.552L7.78,19.871H6.024L4.19,12.127h1.5l1.131,5.418A4.469,4.469,0,0,1,6.9,17.988Z"
                                    style="fill: #fff"
                                />
                            </svg>
                        </span>
                        Download DOCX
                    </button>
                </div>
                <button @click="handleRegenerateMinutes" class="my-3 btn btn-primary">✨ Re-generate minutes</button>
                <button :disabled="!isContentChanged" @click="handleMinutesSave(true)" class="my-3 save-minutes btn tw-red-bg-color text-white">Save Changes</button>
            </div>

            <div v-else class="bg-ai border px-3 py-4 my-5 mx-6">
                <h5 class="text-center my-0">✨ Generate minutes</h5>
                <div class="onboarding bg-danger-50 p-3 mt-3">
                    <div class="row align-items-center hover rounded-1 gap-0 mb-1">
                        <div class="col-auto">
                            <div class="py-1 px-2 rounded bg-danger-50">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="text-danger-400 fa-fw svg-inline--fa fa-plus">
                                    <path
                                        fill="currentColor"
                                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"
                                        class=""
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div class="col py-2">
                            <h6 class="mb-1">
                                <a @click="navigateTo('edit-meeting-agenda')" class="navigateTo">Add at least 3 agenda items</a>
                            </h6>
                            <p class="mb-0">Minutes structure is based on the agenda items</p>
                        </div>
                    </div>
                    <div class="row align-items-center hover rounded-1 gap-0 mb-1">
                        <div class="col-auto">
                            <div class="py-1 px-2 rounded bg-danger-50">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="text-danger-400 fa-fw svg-inline--fa fa-plus">
                                    <path
                                        fill="currentColor"
                                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"
                                        class=""
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div class="col py-2">
                            <h6 class="mb-1">
                                <a @click="navigateTo('meeting-transcript')" class="navigateTo">Add meeting recording</a>
                            </h6>
                            <p class="mb-0">We'll use the transcript from the recording</p>
                        </div>
                    </div>
                </div>
                <p class="text-center">Click “Generate” to start it. You can always tweak the options and re-generate.</p>
                <!---->
                <p class="text-center mb-0">
                    <button @click="generateDraftMinutes" class="btn btn-primary">✨ Generate draft minutes</button>
                </p>
            </div>
        </div>
    </section>
    <div v-if="spinner" class="spinner">
        <span class="ms-2">
            <i class="fa fa-spinner fa-spin fa-lg"></i>
        </span>
        <h3 class="py-3">Generating minutes...</h3>
    </div>
</template>

<script>
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import { mapState } from "vuex";
import router from "../../router";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import Underline from "@tiptap/extension-underline";
import Typography from "@tiptap/extension-typography";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import { usePDF } from "vue3-pdfmake";
import htmlToPdfmake from "html-to-pdfmake";

export default {
    name: "MeetingMinutes",
    props: ["meetingRepo", "list"],
    emits: ["modalHidden", "repositorySaved", "showClerkMinutesPopup", "setParentRepo", "proceed-to-minutes", "agenda-removed", "proceed-to-transcript", "agendaItemsNotPresent", "mediaFileIsNotPresent"],
    components: {
        EditorContent,
    },
    mixins: [heyGovMeetingRepositoryMixin],
    mounted() {
        this.repoId = router.currentRoute?._value.params.id;
        this.editor = new Editor({
            content: "",
            extensions: [
                Markdown,
                StarterKit,
                Typography,
                Link,
                TaskList,
                Highlight,
                Underline,
                TaskItem.configure({ nested: true }),
                Placeholder.configure({
                    placeholder: this.placeholder,
                    emptyEditorClass: "is-editor-empty has-text-primary",
                }),
            ],
            onUpdate: () => {
                this.isContentChanged = this.meetingMinutesContent !== this.editor.getHTML();
            },
        });

        if (!this.repoId) return;
        this.getMeetingRepository(this.repoId).then((response) => {
            if (!this.subscribedToMinutes) {
                router.push("/meeting-repository/edit-meeting-repository/" + this.repoId);
            } else if (!response.data.agenda || response.data.agenda_items.length === 0) {
                router.push("/meeting-repository/edit-meeting-repository/" + this.repoId);
                this.$emit("agendaItemsNotPresent");
            }

            if (response.data?.transcript_job_status === "transcribed") {
                if (response.data?.minutes_text !== "") {
                    this.meetingMinutesContent = response.data?.minutes_text;
                    this.areMinutesGenerated = true;
                    this.editor.commands.setContent(response.data?.minutes_text, false);
                } else {
                    this.areMinutesGenerated = false;
                }
                if (this.meetingRepo.length > 0) return;
                this.$emit("setParentRepo", { ...response.data, title: response.data?.title?.rendered });
            } else {
                router.push("/meeting-repository/edit-meeting-repository/" + this.repoId + "/meeting-transcript");
                this.$emit("mediaFileIsNotPresent");
            }
        });
        this.pdfmake = usePDF({
            autoInstallVFS: true,
        });
    },
    data: () => ({
        meetingMinutesContent: "",
        editor: null,
        placeholder: "",
        areMinutesGenerated: false,
        repoId: null,
        spinner: false,
        isContentChanged: false,
        generateMinutesOptions: {
            model: "claude-3-5-sonnet-20240620",
            temperature: 1.1,
            length: "comprehensive",
            tone: "professional-corporate",
        },
    }),
    computed: {
        ...mapState(["subscribedToMinutes"]),
    },
    beforeUnmount() {
        this.editor.destroy();
    },
    methods: {
        generateDraftMinutes(event) {
            event.preventDefault();
            this.spinner = true;
            this.meetingMinutesContent = "";

            this.generateMinutes(this.meetingRepo, this.generateMinutesOptions).then((response) => {
                this.editor.commands.setContent(response.data, false);
                this.meetingMinutesContent = this.editor.getHTML();
                this.areMinutesGenerated = true;
                this.spinner = false;
                this.handleMinutesSave(false);
            });
        },
        handleRegenerateMinutes(event) {
            this.$swal({
                title: "Are you sure you want to regenerate minutes?",
                text: "All the changes you made in the document will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.generateDraftMinutes(event);
                }
            });
        },
        handleMinutesSave(showPopUpInfo) {
            const fields = {
                minutes_text: this.editor.getHTML() || "No available minutes",
            };
            this.updateMeeting(fields, this.meetingRepo)
                .then(() => {
                    this.isContentChanged = false;
                    if (!showPopUpInfo) return;
                    this.$swal({
                        title: "Updated",
                        text: "Transcript has been updated!",
                        icon: "success",
                    });
                })
                .catch((error) => {
                    console.error("Transcript is not updated.", error);
                });
        },
        handlePdfDownload(event) {
            event.preventDefault();

            const htmlContent = htmlToPdfmake(this.editor.getHTML());

            console.log(htmlContent);

            this.pdfmake
                .createPdf({
                    defaultStyles: {
                        h1: { fontSize: 24, bold: true, marginBottom: 3 },
                        h2: { fontSize: 22, bold: true, marginBottom: 3 },
                        h3: { fontSize: 20, bold: true, marginBottom: 3 },
                        h4: { fontSize: 18, bold: true, marginBottom: 3 },
                    },
                    content: htmlContent,
                })
                .download(this.meetingRepo?.title + "_meeting-minutes");
        },
        handleDocxDownload(event) {
            event.preventDefault();
            this.downloadMinutesAsDocx(this.meetingRepo).then((response) => {
                const url = response.config.url;
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute("download", this.meetingRepo?.title + "_meeting-minutes.docx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        navigateTo(route) {
            router.push("/meeting-repository/edit-meeting-repository/" + this.repoId + "/" + route);
        },
    },
};
</script>
<style scoped>
#meeting-minutes {
    max-width: 80%;
    margin: 0 auto;
}
.editor-toolbar {
    border: 1px solid lightgray;
    border-radius: 10px;
}
.editor-content {
    background-color: #fff;
    border-radius: 10px;
}
.control-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.navigateTo {
    cursor: pointer;
}
.spinner {
    font-size: 5.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 3;
    text-align: center;
}
.spinner span {
    font-size: 5rem;
}
.spinner svg {
    color: #fff;
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}
.bg-ai {
    border-radius: 10px;
    background: linear-gradient(180deg, #fff1d6 0%, #ffffff 38.01%);
}
</style>
<style>
.ProseMirror {
    padding: 1.5rem;
    outline: none;
    cursor: text;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    height: 65vh;
    overflow: auto;
}
</style>
