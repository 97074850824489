<!-- Modal -->
<template>
    <div class="modal fade" id="subscribeToMinutesModal" ref="subscribeToMinutesModal" tabindex="-1" aria-hidden="false">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div :class="['modal-content', { fadedContent: isFaded }]">
                <div class="row">
                    <div class="col-md-6 main-content p-5">
                        <div class="title">
                            <h2>Subscribe to ClerkMinutes to generate meeting minutes.</h2>
                        </div>
                        <div class="subtitle">
                            <p>Generate meeting minutes based on your agenda in minutes, instead of hours.</p>
                        </div>
                        <div class="content mt-5">
                            <ul>
                                <li class="pt-3">
                                    <span class="pe-3">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="40" height="40" rx="14" fill="url(#paint0_linear_43_2548)" />
                                            <g filter="url(#filter0_d_43_2548)">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M10 15.5C10 13.8432 11.3432 12.5 13 12.5H27C28.6569 12.5 30 13.8432 30 15.5V25.5C30 27.1569 28.6569 28.5 27 28.5H13C11.3432 28.5 10 27.1569 10 25.5V15.5ZM14 23.5C14 22.9477 14.4477 22.5 15 22.5H20C20.5523 22.5 21 22.9477 21 23.5C21 24.0523 20.5523 24.5 20 24.5H15C14.4477 24.5 14 24.0523 14 23.5ZM23 23.5C23 22.9477 23.4477 22.5 24 22.5H25C25.5523 22.5 26 22.9477 26 23.5C26 24.0523 25.5523 24.5 25 24.5H24C23.4477 24.5 23 24.0523 23 23.5ZM15 18.5C14.4477 18.5 14 18.9477 14 19.5C14 20.0523 14.4477 20.5 15 20.5H16C16.5523 20.5 17 20.0523 17 19.5C17 18.9477 16.5523 18.5 16 18.5H15ZM20 18.5C19.4477 18.5 19 18.9477 19 19.5C19 20.0523 19.4477 20.5 20 20.5H25C25.5523 20.5 26 20.0523 26 19.5C26 18.9477 25.5523 18.5 25 18.5H20Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_43_2548" x="6" y="12.5" width="28" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0156863 0 0 0 0 0.447059 0 0 0 0 1 0 0 0 1 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_2548" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_43_2548" result="shape" />
                                                </filter>
                                                <linearGradient id="paint0_linear_43_2548" x1="20" y1="0.5" x2="20" y2="40.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#65ADFF" />
                                                    <stop offset="1" stop-color="#0070FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <p><strong>AI Powered Transcripts.</strong> Automatically transcribe spoken words in meetings, ensuring no detail is missed.</p>
                                </li>
                                <li class="pt-3">
                                    <span class="pe-3">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="40" height="40" rx="14" fill="url(#paint0_linear_43_2553)" />
                                            <g filter="url(#filter0_d_43_2553)">
                                                <path d="M21 11.5H15C13.3432 11.5 12 12.8432 12 14.5V20.5C17.3333 20.5 22.6667 20.5 28 20.5V18.5H24C22.3431 18.5 21 17.1568 21 15.5V11.5Z" fill="white" />
                                                <path
                                                    d="M27.8834 16.5001C27.7442 16.0158 27.484 15.5698 27.1213 15.2072L24.2929 12.3787C23.9302 12.0161 23.4843 11.7559 23 11.6167V15.5001C23 16.0523 23.4477 16.5001 24 16.5001H27.8834Z"
                                                    fill="white"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M11 23.5C11 22.9477 11.4477 22.5 12 22.5H13.75C15.1307 22.5 16.25 23.6193 16.25 25C16.25 26.3807 15.1307 27.5 13.75 27.5H13V28.5C13 29.0523 12.5523 29.5 12 29.5C11.4477 29.5 11 29.0523 11 28.5V23.5ZM13 25.5H13.75C14.0261 25.5 14.25 25.2761 14.25 25C14.25 24.7239 14.0261 24.5 13.75 24.5H13V25.5Z"
                                                    fill="white"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17 23.5C17 22.9477 17.4477 22.5 18 22.5H19.25C19.916 22.5 20.822 22.649 21.5931 23.1728C22.4211 23.7352 23 24.6709 23 26C23 27.3291 22.4211 28.2648 21.5931 28.8272C20.822 29.351 19.916 29.5 19.25 29.5H18C17.4477 29.5 17 29.0523 17 28.5V23.5ZM19 24.5V27.5H19.25C19.6673 27.5 20.1364 27.399 20.4694 27.1728C20.7456 26.9852 21 26.6709 21 26C21 25.3291 20.7456 25.0148 20.4694 24.8272C20.1364 24.601 19.6673 24.5 19.25 24.5H19Z"
                                                    fill="white"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M24 23.5C24 22.9477 24.4477 22.5 25 22.5H28C28.5523 22.5 29 22.9477 29 23.5C29 24.0523 28.5523 24.5 28 24.5H26V25.5H27C27.5523 25.5 28 25.9477 28 26.5C28 27.0523 27.5523 27.5 27 27.5H26V28.5C26 29.0523 25.5523 29.5 25 29.5C24.4477 29.5 24 29.0523 24 28.5V23.5Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_43_2553" x="4" y="8.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.27817 0 0 0 0 0.0156863 0 0 0 1 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_2553" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_43_2553" result="shape" />
                                                </filter>
                                                <linearGradient id="paint0_linear_43_2553" x1="20" y1="0.5" x2="20" y2="40.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF8E65" />
                                                    <stop offset="1" stop-color="#FF4400" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <p><strong>Automated Minute Generation.</strong> Create meeting minutes from transcripts & your meeting agenda. Saves you hours of manual work.</p>
                                </li>
                                <li class="pt-3">
                                    <span class="pe-3">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="40" height="40" rx="14" fill="url(#paint0_linear_43_2562)" />
                                            <g filter="url(#filter0_d_43_2562)">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M15 20.5C15 16.0817 18.5817 12.5 23 12.5C27.4183 12.5 31 16.0817 31 20.5C31 24.9183 27.4183 28.5 23 28.5C18.5817 28.5 15 24.9183 15 20.5ZM10 16.5C10 15.9477 10.4477 15.5 11 15.5H13C13.5523 15.5 14 15.9477 14 16.5C14 17.0523 13.5523 17.5 13 17.5H11C10.4477 17.5 10 17.0523 10 16.5ZM23 16.5C23.5523 16.5 24 16.9477 24 17.5V20.0858L25.2071 21.2929C25.5976 21.6834 25.5976 22.3166 25.2071 22.7071C24.8166 23.0976 24.1834 23.0976 23.7929 22.7071L22.2929 21.2071C22.1054 21.0196 22 20.7652 22 20.5V17.5C22 16.9477 22.4477 16.5 23 16.5ZM9 20.5C9 19.9477 9.44772 19.5 10 19.5H12C12.5523 19.5 13 19.9477 13 20.5C13 21.0523 12.5523 21.5 12 21.5H10C9.44772 21.5 9 21.0523 9 20.5ZM10 24.5C10 23.9477 10.4477 23.5 11 23.5H13C13.5523 23.5 14 23.9477 14 24.5C14 25.0523 13.5523 25.5 13 25.5H11C10.4477 25.5 10 25.0523 10 24.5Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_43_2562" x="4" y="8.5" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.0156863 0 0 0 0 0.507843 0 0 0 1 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_2562" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_43_2562" result="shape" />
                                                </filter>
                                                <linearGradient id="paint0_linear_43_2562" x1="20" y1="0.5" x2="20" y2="40.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF65B2" />
                                                    <stop offset="1" stop-color="#FF0080" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <p><strong>Time Efficiency.</strong> Users report cutting their clerical work time in half. Free up your time.</p>
                                </li>
                            </ul>
                            <div v-if="usedMinutes === 0" class="modal-buttons mt-5">
                                <button @click="onRequestDemoClick()" type="button" class="btn btn-primary p-2 my-3">Try for free for 30 days</button>
                                <a href="https://calendly.com/minutes-experts/expert-call" target="_blank">
                                    <button type="button" class="btn btn-secondary p-2 my-3">Request a Demo</button>
                                </a>
                            </div>
                            <div v-else class="modal-buttons contact-btn mt-5">
                                <button @click="showSupportModal" type="button" class="btn btn-primary p-2 my-3">Contact support</button>
                            </div>
                            <div class="disclaimer text-center p-2">
                                <p v-if="usedMinutes === 0">Your trial lasts for 30 days, afterwards, you will be billed $99/mo.</p>
                                <p v-else>Your trial period has expired. Please contact support to activate your subscription.</p>
                                <a v-if="showDisablePopupMessage" href="#" @click="handleShowingPopup">Do not show this again</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 banner-video">
                        <span @click="closeModal()" class="btn fa-stack icon-btn">
                            <i class="tw-light-color fa fa-circle fa-stack-2x"></i>
                            <i class="fas fa-solid fa-times fa-stack-1x fa-inverse"></i>
                        </span>
                        <video v-if="!subscribedToMinutes" ref="modalVideo" src="@/assets/video/clerk.mp4" controls autoplay loop muted></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ContactForMinutesSubscription @supportContactModalClosed="onSupportContactModalClosed" ref="ContactForMinutesSubscription" />
</template>
<script>
import { Modal } from "bootstrap";
import { mapState } from "vuex";
import { useRoute } from "vue-router";
import ContactForMinutesSubscription from "@/components/modals/ContactForMinutesSubscription";

export default {
    name: "SubscribeToMinutesModal",
    components: { ContactForMinutesSubscription },
    props: ["repoId", "showDisablePopupMessage", "usedMinutes"],
    emits: ["popup-subscription"],
    data: () => ({
        modal: null,
        isFaded: false,
    }),
    setup() {
        const route = useRoute();
        return { route };
    },
    mounted() {
        this.modal = new Modal(this.$refs.subscribeToMinutesModal);
    },
    watch: {},
    computed: {
        ...mapState(["subscribedToMinutes"]),
        currentPath() {
            return this.$route.path;
        },
    },
    methods: {
        showModal(value) {
            if (!value) return;
            this.modal.show();
        },
        showSupportModal() {
            this.$refs.ContactForMinutesSubscription.showModal(true);
            this.isFaded = true;
        },
        onSupportContactModalClosed() {
            this.isFaded = false;
        },
        closeModal() {
            this.$refs.modalVideo.pause();
            this.modal.hide();
        },
        onRequestDemoClick() {
            this.modal.hide();
            this.$emit("popup-subscription", this.repoId);
            this.$swal({
                icon: "success",
                title: '<h3 style="text-align:center;line-height: 2.5rem;">Successfully subscribed to ClerkMinutes 30 days trial!</h3>',
                background: "linear-gradient(180deg, #fff1d6 0%, #ffffff 38.01%)",
                customClass: {
                    container: "clerk-minutes-confirmation",
                },
                confirmButtonText: "Proceed",
            });
        },
        handleShowingPopup(event) {
            event.preventDefault();
            this._setCookie("disableClerkMinutesPopup", true);
            this.closeModal();
        },
        _setCookie(id, value) {
            document.cookie = id + "=" + value;
        },
    },
};
</script>

<style>
.clerk-minutes-confirmation .swal2-popup {
    border-radius: 32px;
}
.clerk-minutes-confirmation .swal2-styled.swal2-confirm {
    background-color: #0d6efd !important;
    border-radius: 16px;
}
</style>

<style scoped>
.title {
    font-size: 32px;
    line-height: 42px;
}
.subtitle {
    font-size: 20px;
    line-height: 30px;
}
.main-content {
    background: linear-gradient(180deg, #fff1d6 0%, #ffffff 38.01%);
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}
.modal .modal-dialog .modal-content {
    border-radius: 32px;
}
.modal-buttons {
    display: flex;
    flex-direction: row;
    gap: 2%;
}
.modal-buttons button.btn-secondary {
    background-color: #e7eeff;
    border-color: #e7eeff;
    color: #0255fb;
}
.modal-buttons a {
    flex: 0 0 100%;
    display: flex;
    text-decoration: none;
}
.modal-buttons button {
    flex: 0 0 50%;
    border-radius: 16px;
    font-size: 18px;
    line-height: 27px;
}
.modal-buttons.contact-btn button {
    flex: 0 0 100%;
}
.modal-content.fadedContent {
    opacity: 0.5;
}
.banner-video video {
    height: auto;
    width: 100%;
    position: absolute;
    top: 25%;
}
.banner-video {
    background-color: black;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    position: relative;
    overflow: hidden;
    padding: 0;
}
.content ul {
    padding-left: 0;
}
.content li {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    line-height: 30px;
}
.disclaimer p {
    font-size: 14px;
}
.disclaimer a {
    font-size: 11px;
}
.icon-btn {
    font-size: 1.5rem;
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 10;
}
strong {
    font-weight: 700;
    color: black;
}
</style>
