<template>
    <nav v-if="$route.name !== 'Meeting repository' && siteType === 'cms6'" style="--bs-breadcrumb-divider: ''" aria-label="breadcrumb">
        <ul class="breadcrumb m-2 rounded-pill">
            <li class="breadcrumb-item nav-item" aria-current="page" v-for="(tab, index) in tabs" :key="index">
                <router-link v-show="tab.isShown" v-if="index > 0" :class="['breadcrumb-item-a rounded-pill', { activeCrumb: tab.isActive }]" :to="tab.to">{{ tab.label }}</router-link>
            </li>
            <li v-if="!subscribedToMinutes" class="breadcrumb-item locked-item nav-item" aria-current="page">
                <a href="#" class="breadcrumb-item-a rounded-pill" @click="onMeetingMinutesClick()">
                    <span class="locked-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 9V6C15 3.23858 12.7614 1 10 1C7.23858 1 5 3.23858 5 6V9M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V13.8C19 12.1198 19 11.2798 18.673 10.638C18.3854 10.0735 17.9265 9.6146 17.362 9.32698C16.7202 9 15.8802 9 14.2 9H5.8C4.11984 9 3.27976 9 2.63803 9.32698C2.07354 9.6146 1.6146 10.0735 1.32698 10.638C1 11.2798 1 12.1198 1 13.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                                stroke="black"
                                stroke-opacity="0.4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                    Meeting Minutes
                </a>
            </li>
            <li class="meeting-name tw-red-color text-white ms-0">
                {{ repository.title }}
            </li>
        </ul>
    </nav>
    <div>
        <component
            @agenda-removed="handleOnAgendaRemoved"
            @showClerkMinutesPopup="showClerkMinutesPopup"
            @proceed-to-minutes="navigateToMinutes"
            @setParentRepo="setAllData"
            @proceed-to-transcript="navigateToTranscript"
            @agendaItemsNotPresent="handleAgendaItemsNotPresent"
            @mediaFileIsNotPresent="handleMediaIsNotPresent"
            :meetingRepo="repository"
            :list="agendaItemsList"
            v-bind:is="componentToLoad"
            ref="editMeetingRepo"
        ></component>
    </div>
    <div v-if="!subscribedToMinutes">
        <SubscribeToMinutesModal @popup-subscription="handleSubscription" :usedMinutes="this.usedMinutes" :repoId="repositoryId" :showDisablePopupMessage="showDisablePopupMessage" ref="SubscribeToMinutesModal" />
    </div>
</template>

<script>
import MeetingRepositoryTable from "@/components/meeting-repository/MeetingRepositoryTable.vue";
import EditMeetingRepository from "@/components/meeting-repository/EditMeetingRepository.vue";
import SubscribeToMinutesModal from "@/components/modals/SubscribeToMinutesModal";
import EditAgendaListItems from "../components/meeting-repository/EditAgendaListItems.vue";
import MeetingTranscript from "../components/meeting-repository/MeetingTranscript.vue";
import MeetingMinutes from "../components/meeting-repository/MeetingMinutes.vue";
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import heyGovMixin from "@/mixins/heyGovMixin";

import { mapState } from "vuex";
import router from "../router";

const AGENDA_PROMPT_MSG = '<h3 style="text-align:center;line-height: 2.5rem;">Please upload your agenda file!</h3>';
const MEDIA_PROMPT_MSG = '<h3 style="text-align:center;line-height: 2.5rem;">Please upload your video or audio file!</h3>';

export default {
    name: "MeetingRepository",
    mixins: [heyGovMeetingRepositoryMixin, heyGovMixin],
    components: {
        MeetingRepositoryTable,
        EditMeetingRepository,
        SubscribeToMinutesModal,
        EditAgendaListItems,
        MeetingTranscript,
        MeetingMinutes,
    },
    emits: [],
    watch: {},
    data: () => ({
        repository: [],
        tabs: [],
        repositoryId: null,
        usedMinutes: null,
        agendaItemsList: [],
        isAgendaUploaded: false,
        showDisablePopupMessage: true,
        isMediaUploaded: false,
        isLockedHidden: true,
    }),
    computed: {
        ...mapState(["subscribedToMinutes"]),
        componentToLoad() {
            const componentName = this.$route.meta?.componentName;
            return componentName ? componentName : MeetingRepositoryTable;
        },
    },
    mounted() {
        this.heyGovJurisdiction().then((response) => {
            if (response && this.siteType === "cms6") {
                this.usedMinutes = response.used_clerkminutes;
                // store it in global storage if jurisdiction is subscribed to clerkminutes
                if (response.features.indexOf("clerkminutes") > 0) {
                    setTimeout(() => this.setTabs(), 100);
                    this.$store.commit("subscribedToMinutes", true);
                } else if (this.repositoryId) {
                    router.push("/meeting-repository/edit-meeting-repository/" + this.repositoryId);
                } else {
                    router.push("/meeting-repository/");
                }
            }
        });
    },
    beforeRouteUpdate() {
        // added small delay to handle active tab state
        setTimeout(() => {
            this.repositoryId = this.$route.params.id;
            this.setTabs();
        }, 100);
    },
    methods: {
        handleAgendaItemsNotPresent() {
            this.$swal({
                icon: "info",
                title: AGENDA_PROMPT_MSG,
                background: "linear-gradient(180deg, #fff1d6 0%, #ffffff 38.01%)",
                customClass: {
                    container: "clerk-minutes-confirmation",
                },
                confirmButtonText: "OK",
            });
        },
        handleMediaIsNotPresent() {
            this.$swal({
                icon: "info",
                title: MEDIA_PROMPT_MSG,
                background: "linear-gradient(180deg, #fff1d6 0%, #ffffff 38.01%)",
                customClass: {
                    container: "clerk-minutes-confirmation",
                },
                confirmButtonText: "OK",
            });
        },
        handleOnAgendaRemoved() {
            this.agendaItemsList = [];
        },
        navigateToMinutes(repository) {
            if (!repository) return;
            this.meetingRepo = repository;
            this.agendaItemsList = repository?.agenda_items;
            router.push(`${this.$route.path}/edit-meeting-agenda`);
        },

        handleSubscription() {
            this.$store.commit("subscribedToMinutes", true);
            if (this.siteType === "cms6") {
                this.startClerkMinutes()
                    .then(() => {
                        this.$store.commit("subscribedToMinutes", true);
                        setTimeout(() => this.setTabs(), 200);
                    })
                    .catch((error) => {
                        console.error("Error starting ClerkMinutes", error);
                    });
            }
        },

        navigateToTranscript() {
            router.push(`/meeting-repository/edit-meeting-repository/${this.$route.params?.id}/meeting-transcript`);
        },

        setAllData(repository) {
            this.repository = repository;
            this.agendaItemsList = repository.agenda_items;
        },

        setTabs() {
            const route = this.$route;
            const matchedRoutes = route?.matched;
            let children;
            const currentRouteName = route.name;

            const availableRoutes = matchedRoutes.map((matchedRoute) => {
                const addedPathId = this._replaceId(matchedRoute.path, this.repositoryId || router.currentRoute?._value.params.id);
                if (matchedRoute.children.length > 0) {
                    children = matchedRoute.children.map((child) => {
                        return {
                            ...child,
                            path: addedPathId + "/" + child.path,
                            show: this.subscribedToMinutes || false,
                        };
                    });
                } else {
                    return {
                        ...matchedRoute,
                        show: false,
                    };
                }
                return {
                    ...matchedRoute,
                    path: addedPathId,
                    show: true,
                };
            });
            const allRoutes = [...availableRoutes, ...children];

            this.tabs = allRoutes.map((routeItem) => {
                return {
                    label: routeItem?.breadcrumb || routeItem?.name, //fallback on router page name
                    to: routeItem?.path,
                    isActive: currentRouteName === routeItem?.name,
                    isShown: routeItem?.show,
                };
            });
        },

        showClerkMinutesPopup() {
            if (!this._getCookie("disableClerkMinutesPopup") && !this.subscribedToMinutes && this.siteType === "cms6") {
                this.$refs.SubscribeToMinutesModal.showModal(true);
            }
        },
        onMeetingMinutesClick() {
            this.showDisablePopupMessage = false;
            this.$refs.SubscribeToMinutesModal.showModal(true);
        },
        _getCookie(id) {
            let value = document.cookie.match("(^|;)?" + id + "=([^;]*)(;|$)");
            return value ? unescape(value[2]) : null;
        },
        _replaceId(url, correctId) {
            let segments = url.split("/");
            let idIndex = segments.lastIndexOf(":id");

            if (idIndex !== -1) {
                segments[idIndex] = correctId;
                return segments.join("/");
            }
            return url;
        },
    },
};
</script>

<style scoped>
a {
    color: inherit;
    text-decoration: none;
}
.breadcrumb {
    background: #fff;
    padding: 12px 5px;
}
.locked-item .breadcrumb-item-a {
    color: #8c8c8c;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(236, 38, 64, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(236, 38, 64, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(236, 38, 64, 0.5);
}
.breadcrumb-item {
    padding: 0;
}
.breadcrumb-item-a {
    padding: 8px 16px;
}
.activeCrumb {
    background-color: #f6f6f6;
}
.locked-icon svg {
    margin: -4px 3px 0 0;
}
.meeting-name {
    position: absolute;
    right: 3%;
}
</style>
