<template>
    <draggable :class="['drag-area', { 'drag-area-edit pt-2': editMode }]" tag="ul" :handle="'.drag-and-drop-item'" :list="list" v-bind="dragOptions" :group="{ name: 'g1' }" item-key="id">
        <template #item="{ element, index }">
            <li :class="[`list-group-item agenda-list-item`, { 'with-subitems': element.subitems?.length > 0 }, { 'edit-hover': editMode }]" @end="onSubMoveEnd" :itemid="element.id">
                <div v-if="editMode && element.isDeletePromptShown" class="delete-msg py-1">
                    <span class="p-2">{{ deletionPromptMsg }}</span>
                    <div v-show="!arePromptButtonsHidden" class="confirmation-buttons">
                        <button class="btn btn-danger btn-sm mx-1" @click="onDeleteItemConfirm(element, index)" type="button">Yes</button>
                        <button class="btn btn-secondary btn-sm mx-1" @click="closeDeletePrompt(element)" type="button">No</button>
                    </div>
                </div>
                <input class="form-control" v-show="element.edit" v-model="element.title" type="text" />
                <div :class="['item-heading p-2', { 'drag-item-edit': editMode }]" v-show="!element.edit">
                    <span v-show="editMode" class="drag-and-drop-item pe-3">
                        <i class="fas fa-bars"></i>
                    </span>
                    <span>{{ index + 1 }}. {{ element.title }}</span>
                </div>
                <div v-if="editMode" class="edit-save-icons">
                    <span v-show="!element.edit" @click="editItem(element)" class="fa-stack icon-btn">
                        <i class="tw-light-red-color fa fa-circle fa-stack-2x"></i>
                        <i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i>
                    </span>
                    <span v-show="element.edit" @click="saveItem(element)" class="fa-stack icon-btn">
                        <i class="tw-light-red-color fa fa-circle fa-stack-2x"></i>
                        <i class="fas fa-solid fa-check fa-stack-1x fa-inverse"></i>
                    </span>
                    <span v-show="!element.edit" @click="deleteItem(element)" class="fa-stack icon-btn">
                        <i class="tw-light-red-color fa fa-circle fa-stack-2x"></i>
                        <i class="fa fa-trash fa-stack-1x fa-inverse"></i>
                    </span>
                </div>

                <nested-draggable @itemDelete="onDeleteItemConfirm" :level="level + 1" :repository="repository" :editMode="editMode" :list="element.subitems" />
            </li>
        </template>
    </draggable>
</template>
<script>
import draggable from "vuedraggable";
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";

export default {
    props: {
        list: {
            required: true,
            type: Array,
            default: () => [],
        },
        editMode: {
            type: Boolean,
        },
        repository: {
            type: Object,
        },
        level: {
            type: Number,
            default: 1,
        },
    },
    mixins: [heyGovMeetingRepositoryMixin],
    emits: ["itemDelete", "itemSave", "update:list"],
    data: () => ({
        deletePrompt: false,
        deletionPromptMsg: "",
        arePromptButtonsHidden: false,
    }),
    components: {
        draggable,
    },
    name: "nested-draggable",
    computed: {
        dragOptions() {
            return {
                animation: 250,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                filter: ".no-drag",
            };
        },
    },
    methods: {
        editItem(agendaItem) {
            if (!agendaItem) return;
            agendaItem.edit = true;
        },
        saveItem(agendaItem) {
            if (!agendaItem) return;
            agendaItem.edit = false;
            const changes = {
                // additional changes per edited item can be added here
                title: agendaItem.title,
            };
            this.editAgendaItem(this.repository, agendaItem.id, changes);
        },
        deleteItem(agendaItem) {
            if (!agendaItem) return;

            this.deletionPromptMsg = "Are you sure?";
            this.arePromptButtonsHidden = false;
            agendaItem.isDeletePromptShown = true;
        },
        onDeleteItemConfirm(agendaItem, index) {
            this.deletionPromptMsg = "Item is deleted!";
            this.arePromptButtonsHidden = true;

            const parentId = agendaItem?.parent_id;
            console.log(parentId);

            setTimeout(() => {
                this.$emit("itemDelete", agendaItem, index);
                agendaItem.isDeletePromptShown = false;
            }, 300);
        },
        closeDeletePrompt(agendaItem) {
            agendaItem.isDeletePromptShown = false;
        },
        onSubMoveEnd() {
            let updatedSubItemsArr = this.list;
            // Limit subitems level to one.
            updatedSubItemsArr.forEach((item, index) => {
                item.subitems.forEach((subitem) => {
                    if (subitem.subitems.length > 0) {
                        subitem.subitems.forEach((subitem1, subitem1Index) => {
                            subitem.subitems.splice(subitem1Index, 1);
                            updatedSubItemsArr[index].subitems.push(subitem1);
                            if (subitem1.subitems.length > 0) {
                                subitem1.subitems.forEach((subitem2, subitem1Index) => {
                                    subitem1.subitems.splice(subitem1Index, 1);
                                    updatedSubItemsArr[index].subitems.push(subitem2);
                                    if (subitem2.subitems.length > 0) {
                                        subitem2.subitems.forEach((subitem3, subitem1Index) => {
                                            subitem2.subitems.splice(subitem1Index, 1);
                                            updatedSubItemsArr[index].subitems.push(subitem3);
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            });
            this.$emit("update:list", updatedSubItemsArr);
        },
    },
};
</script>
<style scoped>
.drag-area.drag-area-edit {
    min-height: 10px;
}
.drag-item {
    position: relative;
}
.item-heading {
    max-width: 90%;
}
.item-heading.drag-item-edit {
    border-bottom: 1px dashed lightgray;
}
.edit-save-icons {
    position: absolute;
    right: 0;
    top: 3px;
}
.list-group-item {
    line-height: 1.5rem;
}
.list-group-item.agenda-list-item {
    display: flex;
    flex-direction: column;
    position: relative;
    border: none;
}
.icon-btn .fa-circle {
    width: 1.85rem;
}
.delete-msg {
    position: absolute;
    width: 35%;
    text-align: center;
    background-color: #f0808075;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 28%;
}
.list-group-item.agenda-list-item.with-subitems .delete-msg {
    top: 10%;
}
.list-group-item.agenda-list-item.with-subitems ul .delete-msg {
    top: 35%;
}
.confirmation-buttons {
    display: inline-block;
}
.have-subitems .drag-area .list-group-item.agenda-list-item .drag-area.drag-area-edit {
    display: none;
}
</style>
